import React, {FC, MouseEventHandler, useState} from 'react';
import {IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import styles from "./HeaderWithMenu.module.scss";

interface HeaderWithMenuProps {
    title: string
    isMarked?: boolean
    onDelete?: () => void
    onEdit?: () => void
}

const HeaderWithMenu: FC<HeaderWithMenuProps> = ({ title, onEdit, onDelete, isMarked = true}) => {

    const handleClickEdit: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onEdit && onEdit()
    }

    const handleClickDelete: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onDelete && onDelete()
    }

    return (
        <div className={styles.wrapper}>
            <p>{title}</p>
            <div className={styles.wrapperButtons}>
                {onEdit && <IconButton aria-label="delete" sx={{color: isMarked ? 'black' : 'white'}} size="small"
                                       onClick={handleClickDelete}>
                    <DeleteIcon/>
                </IconButton>}
                {onDelete && <IconButton aria-label="edit" sx={{color: isMarked ? 'black' : 'white'}} size="small"
                                         onClick={handleClickEdit}>
                    <EditIcon/>
                </IconButton>}
            </div>
        </div>
    );
};

export default HeaderWithMenu;