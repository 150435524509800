import { canRedirect, transformParamsToUrl } from '@shared/helpers'
import { ApiServiceResponseType, ObjectType, Params, baseConfig } from './types'

const ApiService = {
    get: function (url: string, config?: RequestInit): ApiServiceResponseType {
        const newConfig: RequestInit = {
            ...baseConfig,
            ...config,
            method: 'GET'
        }
        return fetch(url, newConfig).then(canRedirect)
    },

    post: function (
        url: string,
        data: ObjectType | Array<any>,
        config?: RequestInit
    ): ApiServiceResponseType {
        const newConfig: RequestInit = {
            ...baseConfig,
            ...config,
            method: 'POST',
            body: typeof data === 'string' ? data : JSON.stringify(data)
        }
        return fetch(url, newConfig).then(canRedirect)
    },

    postFormData: function (
        url: string,
        data: FormData,
        config?: RequestInit
    ): ApiServiceResponseType {
        const newConfig: RequestInit = {
            ...config,
            method: 'POST',
            body: data
        }
        return fetch(url, newConfig).then(canRedirect)
    },

    put: function (url: string, data: ObjectType, config?: RequestInit): ApiServiceResponseType {
        const newConfig: RequestInit = {
            ...baseConfig,
            ...config,
            method: 'PUT',
            body: JSON.stringify(data)
        }
        return fetch(url, newConfig).then(canRedirect)
    },

    delete: function (url: string, config?: RequestInit): ApiServiceResponseType {
        const newConfig: RequestInit = {
            ...baseConfig,
            ...config,
            method: 'DELETE'
        }
        return fetch(url, newConfig).then(canRedirect)
    },
}

export default ApiService
