import React, {ChangeEventHandler, useEffect, useMemo, useState} from 'react';
import Header from "@components/Header/Header";
import LinkButton from "@components/LinkButton/LinkButton";
import {IObservation, IViewObservation} from "@interfaces/observation";
import {useDataBase} from "@hooks/useDataBase";
import {executeQueryDataBase, saveDataBase} from "@shared/helpersDB";
import {
    SELECT_INFECTION_FROM_WEEDS,
    SELECT_OBSERVATIONS_FROM_OBSERVATIONS,
    SELECT_OBSERVATIONS_FROM_WEEDS,
    SELECT_PHENOPHASES_FROM_WEEDS, SELECT_QUARANT_OBJ_FROM_WEEDS
} from "@constants/selectQuery";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Wrapper from "@components/Wrapper/Wrapper";
import ObservationCard from "@components/ObservationCard/ObservationCard";
import Input from "@components/Input/Input";
import {useDebounce} from "@hooks/useDebounce";
import {DELETE_OBSERVATION_FROM_OBSERVATIONS} from "@constants/deleteQuery";
import {OBSERVATIONS_DATABASE, WEEDS_DATABASE} from "@constants/dataBase";
import ConfirmationModal from "@components/Modal/ConfirmationModal/ConfirmationModal";
import {IPhenophase} from "@interfaces/phenophases";
import {IInfection} from "@interfaces/infection";

const Observations = () => {

    const {weedsDataBase, observationsDataBase} = useDataBase()
    const {crop_stage_id} = useParams()
    const {state}: { state: { editable: boolean } } = useLocation()
    const navigate = useNavigate()

    const [observations, setObservations] = useState<Array<IViewObservation>>([])
    const [search, setSearch] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [currentObservation, setCurrentObservation] = useState<IViewObservation | null>(null)

    const debouncedSearch = useDebounce(search)

    const filteredObservations = useMemo(() => {
        if (debouncedSearch == '') return observations;
        return observations.filter(observation => observation.infection.toLowerCase().startsWith(debouncedSearch.toLowerCase()))
    }, [observations, debouncedSearch])

    useEffect(() => {
        if (!weedsDataBase || !observationsDataBase) return;
        const observations = executeQueryDataBase<IObservation>(observationsDataBase, SELECT_OBSERVATIONS_FROM_OBSERVATIONS, {
            '$crop_stage_id': crop_stage_id || ''
        })
        setObservations(observations.map(observation => {
            const phenophase = executeQueryDataBase<IPhenophase>(weedsDataBase, SELECT_PHENOPHASES_FROM_WEEDS, {
                '$phenophase_id': observation.phenophase_id
            })[0]
            const infection = executeQueryDataBase<IInfection>(weedsDataBase, SELECT_INFECTION_FROM_WEEDS, {
                '$infection_id': observation.infection_id
            })[0]
            return {
                ...observation,
                phenophase: phenophase.name,
                infection: infection.latname
            }
        }))
    }, [weedsDataBase]);

    const handleChangeSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
        setSearch(event.target.value)
    }

    const handleEditObservation = (observation: IViewObservation) => {
        navigate(observation.id.toString())
    }

    const handleOpenConfirmation = async (observation: IViewObservation) => {
        setIsOpen(true)
        setCurrentObservation(observation)
    }

    const handleCloseModal = () => {
        setIsOpen(false)
    }

    const deleteObservation = async () => {
        if (!observationsDataBase || !currentObservation?.id) return;
        executeQueryDataBase(observationsDataBase, DELETE_OBSERVATION_FROM_OBSERVATIONS, {
            '$id': currentObservation.id
        })
        await saveDataBase(OBSERVATIONS_DATABASE, observationsDataBase)
        setObservations(observations => observations.filter(observation => observation.id !== currentObservation.id))
        setCurrentObservation(null)
        handleCloseModal()
    }

    return (
        <>
            <Header title={'Наблюдения'}/>
            <Input placeholder={'Поиск по инфекции'} value={search} onChange={handleChangeSearch}/>
            <LinkButton to={'new_observation'}>Добавить наблюдение</LinkButton>
            <Wrapper>
                {filteredObservations.length !== 0 ?
                    filteredObservations.map(observation =>
                        <ObservationCard
                            key={observation.id}
                            observation={observation}
                            onEdit={state.editable ? handleEditObservation : undefined}
                            onDelete={state.editable ? handleOpenConfirmation : undefined}
                        />
                    ) :
                    <p style={{textAlign: 'center'}}>Данных нет</p>
                }
            </Wrapper>
            <ConfirmationModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={'Вы уверены, что хотите удалить наблюдение?'}
                subtitle={'Отменить данное действие будет невозможно.'}
                onReject={handleCloseModal}
                onResolve={deleteObservation}
            />
        </>
    );
};

export default Observations;