import React, {CSSProperties, FC} from 'react';
import logo from '@assets/img/logo.png'
import arrow from '@assets/img/arrowBack.png'

import styles from './Header.module.scss'
import {useNavigate} from "react-router-dom";

interface HeaderProps {
    title: string
    titleProps?: CSSProperties
}

const Header: FC<HeaderProps> = ({title, titleProps}) => {

    const navigate = useNavigate();

    const handleClickLogo = () => {
        navigate('/')
    }
    const handleClickBack = () => {
        navigate(-1)
    }

    return (
        <div className={styles.wrap}>
            <img src={logo} alt="logo" className={styles.logo} onClick={handleClickLogo}/>
            <p className={styles.title} style={titleProps}>{title}</p>
            <button onClick={handleClickBack} className={styles.btn}><img src={arrow} alt="back" /></button>
        </div>
    );
};

export default Header;