export const INITIAL_CREATE_OBSERVATIONS_DATABASE = `
    CREATE TABLE IF NOT EXISTS crop_stages (
        id text PRIMARY KEY,
        crop_id integer,
        date text,
        phenophase_id integer,
        createdAt string,
        isSync integer,
        isDeleted integer
    );
    CREATE TABLE IF NOT EXISTS observations (
        id text PRIMARY KEY,
        crop_stage_id integer,
        infection_id integer,
        phenophase_id integer,
        place text,
        fullness text,
        user text,
        note text,
        isSync integer,
        isDeleted integer,
        FOREIGN KEY (crop_stage_id) REFERENCES crop_stages(id) ON DELETE CASCADE
    );
`

export const INITIAL_CREATE_WEEDS_DATABASE = `
    PRAGMA foreign_keys = ON;
    CREATE TABLE IF NOT EXISTS crops (id integer PRIMARY KEY AUTOINCREMENT, name text, cult_id integer, reseeding integer, cult_type_id integer, region_id integer, district_id integer, year text, field text);
    CREATE TABLE IF NOT EXISTS cults (id integer PRIMARY KEY AUTOINCREMENT, name text, phenoclass_id integer);
    CREATE TABLE IF NOT EXISTS cult_types (id integer PRIMARY KEY AUTOINCREMENT, name text);
    CREATE TABLE IF NOT EXISTS regions (id integer PRIMARY KEY AUTOINCREMENT, name text);
    CREATE TABLE IF NOT EXISTS districts (id integer PRIMARY KEY AUTOINCREMENT, name text);
    CREATE TABLE IF NOT EXISTS phenophases (id integer PRIMARY KEY AUTOINCREMENT, phenoclass_id integer, name text);
    CREATE TABLE IF NOT EXISTS phenoclasses (id integer PRIMARY KEY AUTOINCREMENT, phenoclass text);
    CREATE TABLE IF NOT EXISTS infections (id integer PRIMARY KEY AUTOINCREMENT, rusname text, latname text, phenoclass_id integer, quarantobj_id integer, type text);
    CREATE TABLE IF NOT EXISTS quarantobj (id integer PRIMARY KEY AUTOINCREMENT, latname text);
    CREATE TABLE IF NOT EXISTS notification (id integer PRIMARY KEY AUTOINCREMENT, title text, message text, isRead integer, createdAt text);
    
    INSERT INTO crops 
    SELECT 0, 'ПЕР-ПЕРВ-ПЕ-00-ЕАЕ-2023', 0, 0, NULL, 0, 0, '2023', 'ЕАЕ'
    WHERE NOT EXISTS (
        SELECT 1
        FROM crops
        LIMIT 1
    )
    UNION ALL
    SELECT 1, 'ПЕР-ПЕРВ-ПЕ-00-ААА-2023-П', 0, 0, NULL, 0, 0, '2023', 'ААА'
    WHERE NOT EXISTS (
        SELECT 1
        FROM crops
        LIMIT 1
    )
    UNION ALL
    SELECT 2, 'ВТО-ПЕРВ-ВТ-ПЕ-УЕУ-2023', 1, 0, 0, 1, 0, '2023', 'УЕУ'
    WHERE NOT EXISTS (
        SELECT 1
        FROM crops
        LIMIT 1
    )
    UNION ALL
    SELECT 3, 'ВТО-ПЕРВ-ВТ-ПЕ-ЕНЕ-2023', 1, 0, 0, 1, 0, '2023', 'ЕНЕ'
    WHERE NOT EXISTS (
        SELECT 1
        FROM crops
        LIMIT 1
    )
    UNION ALL
    SELECT 4, 'ВТО-ПЕРВ-ПЕ-ПЕ-УЕУ-2023-П', 2, 0, 0, 1, 0, '2023', 'УЕУ'
    WHERE NOT EXISTS (
        SELECT 1
        FROM crops
        LIMIT 1
    )
    UNION ALL
    SELECT 5, 'ВТО-ПЕРВ-ПЕ-ПЕ-ЕЕЕ-2023', 2, 0, 0, 1, 0, '2023', 'ЕЕЕ'
    WHERE NOT EXISTS (
        SELECT 1
        FROM crops
        LIMIT 1
    );
    
    INSERT INTO cults
    SELECT 0, 'Первая культура', 1
    WHERE NOT EXISTS (
        SELECT 1
        FROM cults
        LIMIT 1
    )
    UNION ALL
    SELECT 1, 'Вторая культура', 2
    WHERE NOT EXISTS (
        SELECT 1
        FROM cults
        LIMIT 1
    )
    UNION ALL
    SELECT 2, 'Третяя культура', 3
    WHERE NOT EXISTS (
        SELECT 1
        FROM cults
        LIMIT 1
    );
    
    INSERT INTO cult_types
    SELECT 0, 'Первый тип культуры'
    WHERE NOT EXISTS (
        SELECT 1
        FROM cult_types
        LIMIT 1
    );
    
    INSERT INTO regions
    SELECT 0, 'Первый регион'
    WHERE NOT EXISTS (
        SELECT 1
        FROM regions
        LIMIT 1
    )
    UNION ALL
    SELECT 1, 'Второй регион'
    WHERE NOT EXISTS (
        SELECT 1
        FROM regions
        LIMIT 1
    );
    
    INSERT INTO districts
    SELECT 0, 'Первый район'
     WHERE NOT EXISTS (
        SELECT 1
        FROM districts
        LIMIT 1
    );
    
    INSERT INTO phenoclasses
    SELECT 1, 'Первый фенокласс'
    WHERE NOT EXISTS (
        SELECT 1
        FROM phenoclasses
        LIMIT 1
    )
    UNION ALL
    SELECT 2, 'Второй фенокласс'
    WHERE NOT EXISTS (
        SELECT 1
        FROM phenoclasses
        LIMIT 1
    )
    UNION ALL
    SELECT 3, 'Третий фенокласс'
    WHERE NOT EXISTS (
        SELECT 1
        FROM phenoclasses
        LIMIT 1
    )
    UNION ALL
    SELECT 4, 'Фенокласс инфекции 1'
    WHERE NOT EXISTS (
        SELECT 1
        FROM phenoclasses
        LIMIT 1
    )
    UNION ALL
    SELECT 5, 'Фенокласс инфекции 2'
    WHERE NOT EXISTS (
        SELECT 1
        FROM phenoclasses
        LIMIT 1
    );
    
    INSERT INTO phenophases
    SELECT 1, 1, 'Первая фенофаза первого фенокласса'
    WHERE NOT EXISTS (
        SELECT 1
        FROM phenophases
        LIMIT 1
    )
    UNION ALL
    SELECT 2, 1, 'Вторая фенофаза первого фенокласса'
    WHERE NOT EXISTS (
        SELECT 1
        FROM phenophases
        LIMIT 1
    )
    UNION ALL
    SELECT 3, 2, 'Первая фенофаза второго фенокласса'
    WHERE NOT EXISTS (
        SELECT 1
        FROM phenophases
        LIMIT 1
    )
    UNION ALL
    SELECT 4, 2, 'Вторая фенофаза второго фенокласса'
    WHERE NOT EXISTS (
        SELECT 1
        FROM phenophases
        LIMIT 1
    )
    UNION ALL
    SELECT 5, 3, 'Первая фенофаза третего фенокласса'
    WHERE NOT EXISTS (
        SELECT 1
        FROM phenophases
        LIMIT 1
    )
    UNION ALL
    SELECT 6, 3, 'Вторая фенофаза третего фенокласса'
    WHERE NOT EXISTS (
        SELECT 1
        FROM phenophases
        LIMIT 1
    )
    UNION ALL
    SELECT 7, 4, 'Фенофаза первого фенокласса инфекции 1'
    WHERE NOT EXISTS (
        SELECT 1
        FROM phenophases
        LIMIT 1
    )
    UNION ALL
    SELECT 8, 4, 'Фенофаза второго фенокласса инфекции 1'
    WHERE NOT EXISTS (
        SELECT 1
        FROM phenophases
        LIMIT 1
    )
    UNION ALL
    SELECT 9, 5, 'Фенофаза первого фенокласса инфекции 2'
    WHERE NOT EXISTS (
        SELECT 1
        FROM phenophases
        LIMIT 1
    )
    UNION ALL
    SELECT 10, 5, 'Фенофаза второго фенокласса инфекции 2'
    WHERE NOT EXISTS (
        SELECT 1
        FROM phenophases
        LIMIT 1
    );
    
    INSERT INTO infections
    SELECT 1, 'Инфекция 1', 'Infection 1', 4, 1, 'Грибы'
    WHERE NOT EXISTS (
        SELECT 1
        FROM infections
        LIMIT 1
    )
    UNION ALL
    SELECT 2, 'Инфекция 2', 'Infection 2', 5, 2, 'Нематоды'
    WHERE NOT EXISTS (
        SELECT 1
        FROM infections
        LIMIT 1
    );
    
    INSERT INTO quarantobj
    SELECT 1 , 'Quarant object 1'
    WHERE NOT EXISTS (
        SELECT 1
        FROM quarantobj
        LIMIT 1
    )
    UNION ALL 
    SELECT 2 , 'Quarant object 2'
    WHERE NOT EXISTS (
        SELECT 1
        FROM quarantobj
        LIMIT 1
    );
    
    INSERT INTO notification (title, message, isRead, createdAt)
    SELECT 'Новое оповещение', 'Какое-то сообщение', 0, '2023-11-13T15:34:00'
    WHERE NOT EXISTS (
        SELECT 1
        FROM notification
        LIMIT 1
    )
    UNION ALL
    SELECT 'Новое оповещение', 'Какое-то сообщение', 0, '2023-11-13T15:34:00'
    WHERE NOT EXISTS (
        SELECT 1
        FROM notification
        LIMIT 1
    )
    UNION ALL
    SELECT 'Новое оповещение', 'Какое-то сообщение', 0, '2023-11-13T15:34:00'
    WHERE NOT EXISTS (
        SELECT 1
        FROM notification
        LIMIT 1
    )
    UNION ALL
    SELECT 'Новое оповещение', 'Какое-то сообщение', 0, '2023-11-13T15:34:00'
    WHERE NOT EXISTS (
        SELECT 1
        FROM notification
        LIMIT 1
    )
    UNION ALL
    SELECT 'Новое длинное оповещение', 'Какое-то длинное оповещение Какое-то длинное оповещение Какое-то длинное оповещение Какое-то длинное оповещение Какое-то длинное оповещение Какое-то длинное оповещение Какое-то длинное оповещение Какое-то длинное оповещение Какое-то длинное оповещение Какое-то длинное оповещение Какое-то длинное оповещение Какое-то длинное оповещение Какое-то длинное оповещение', 0, '2023-11-12T16:19:00'
    WHERE NOT EXISTS (
        SELECT 1
        FROM notification
        LIMIT 1
    );
`

/*

  INSERT INTO quarantobj VALUES (1 , 'Quarant object 1');
  INSERT INTO quarantobj VALUES (2 , 'Quarant object 2');

*/