import React from 'react';
import {createRoutesFromElements, Route, RouteObject} from "react-router-dom";
import {Root} from "@routes/root";
import {Main} from '@routes/main'
import {SearchCrops} from "@routes/searchCrops";
import {Crops} from "@routes/crops";
import {CropStages} from "@routes/cropStages";
import {NewCropStage} from "@routes/newCropStage";
import {Observations} from "@routes/observations";
import {NewObservation} from "@routes/newObservation";
import {Observation} from "@routes/observation";
import {Stage} from "@routes/stage";
import {Synchronization} from "@routes/synchronization";
import Notifications from "./notifications/page/notifications";

export const routes = createRoutesFromElements(
    <Route path={'/'} element={<Root/>}>
        <Route index element={<Main/>}/>
        <Route path={'search'} element={<SearchCrops/>}/>
        <Route path={'crops'}>
            <Route index element={<Crops/>}/>
            <Route path={':crop_id'}>
                <Route index element={<CropStages/>}/>
                <Route path={'new_crop_stage'} element={<NewCropStage/>}/>
                <Route path={':crop_stage_id'} >
                    <Route index element={<Observations/>}/>
                    <Route path={'new_observation'} element={<NewObservation/>}/>
                    <Route path={':observation_id'} element={<Observation />}/>
                    <Route path={'edit'} element={<Stage />}/>
                </Route>
            </Route>
        </Route>
        <Route path={'synchronization'} element={<Synchronization />} />
        <Route path={'notifications'} element={<Notifications />}/>
    </Route>
)