import moment from 'moment'
import {Params} from "@services/types";
export const compareDate = <T extends {createdAt: string}>(a: T, b: T) => {
    if (moment(a.createdAt).isAfter(b.createdAt)) {
        return 1
    } else {
        return -1
    }
}

export const swipePower = (offset: number, absDistance: number) =>  (offset / absDistance) * 100

export const getNotificationButtonWidth = (fullWidthBlock: number, countButtons: number, gap: number) => {
    return (fullWidthBlock / 2) / countButtons - gap
}

export const getNotificationButtonRightOffset = (widthButton: number, numberButtonOnRightSide: number, gap: number) => {
    return (widthButton * (numberButtonOnRightSide - 1)) + ((numberButtonOnRightSide - 1) * gap)
}

export const canRedirect = (res: Response): Promise<Response> =>
    new Promise((resolve, reject) => {
        if (res.redirected) {
            window.location.href = res.url
        }
        resolve(res)
    })

export const transformParamsToUrl = (params: Array<Params>) => {
    let paramsString = '?'
    params.forEach((param, index) => {
        paramsString+=param.param + '=' + param.value
        if (index !== params.length - 1) {
            paramsString+= '&'
        }
    })
    return paramsString
}

export const mergedUint8Arrays = (chunks: Uint8Array[]): Uint8Array => {
    let length = 0
    let offset = 0

    chunks.forEach(chunk => {
        length += chunk.length
    })

    const mergedArray = new Uint8Array(length)

    chunks.forEach(chunk => {
        mergedArray.set(chunk, offset);
        offset += chunk.length;
    })

    return mergedArray
}