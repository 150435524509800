import React, {FC, PropsWithChildren} from 'react';

import styles from './Wrapper.module.scss'

interface WrapperProps extends PropsWithChildren {
    gap?: number
}
const Wrapper: FC<WrapperProps> = ({gap = 10, children}) => {
    return (
        <div className={styles.wrapper} style={{gap: gap + 'px'}}>
            {children}
        </div>
    );
};

export default Wrapper;