import React from 'react';
import Header from "@components/Header/Header";
import { LoadingIndicator } from '@app/components/Indicators/LoadingIndicator/LoadingIndicator';
import { FailedIndicator } from '@app/components/Indicators/FailedIndicator/FailedIndicator';
import {SuccessIndicator} from "@components/Indicators/SuccessIndicator/SuccessIndicator";

import styles from './synchronization.module.scss'
import Wrapper from "@components/Wrapper/Wrapper";
import SynchronizationObservation from "@components/SynchronizationObservation/SynchronizationObservation";
import SynchronizationWeeds from "@components/SynchronizationWeeds/SynchronizationWeeds";
import {WEEDS_DATABASE} from "@constants/dataBase";


const Synchronization = () => {

    return (
        <>
            <Header title={'Синхронизация данных'} titleProps={{fontSize: '20px'}}/>
            <Wrapper gap={40}>
               <Wrapper gap={20}>
                   <p>Наблюдения</p>
                   <SynchronizationObservation />
               </Wrapper>
                <Wrapper gap={20}>
                    <p>Сорные растения</p>
                    <SynchronizationWeeds dataBaseName={WEEDS_DATABASE}/>
                </Wrapper>
            </Wrapper>
        </>
    );
};

export default Synchronization;