export const SELECT_YEAR_FROM_WEEDS = `
    SELECT year
    FROM crops
    GROUP BY year
`
export const SELECT_REGIONS_FROM_WEEDS = `
    SELECT r.id as id, r.name as name 
    FROM regions as r, crops as c 
    WHERE c.year=$year AND c.region_id = r.id
    GROUP BY r.id
`

export const SELECT_DISTRICTS_FROM_WEEDS = `
    SELECT d.id as id, d.name as name 
    FROM districts as d, crops as c 
    WHERE c.year=$year AND c.region_id=$region_id AND c.district_id = d.id
    GROUP BY d.id
`

export const SELECT_CULTURES_FROM_WEEDS = `
    SELECT ct.id as id, ct.rusname as name 
    FROM cults as ct, crops as c 
    WHERE c.year=$year AND c.region_id=$region_id AND c.district_id=$district_id AND c.cult_id = ct.id
    GROUP BY ct.id
`

export const SELECT_CULT_TYPES_FROM_WEEDS = `
    SELECT ctt.id as id, ctt.name as name 
    FROM cult_types as ctt, crops as c 
    WHERE c.year=$year AND c.region_id=$region_id AND c.district_id=$district_id AND c.cult_id=$cult_id AND c.cult_type_id = ctt.id
    GROUP BY ctt.id
`

export const SELECT_CROPS_FROM_WEEDS = `
    SELECT id, name
    FROM crops
    WHERE year=$year AND region_id=$region_id AND district_id=$district_id AND cult_id=$cult_id AND (cult_type_id=$cult_type_id OR cult_type_id IS NULL OR cult_type_id='')
`

export const SELECT_CROP_STAGES_BY_CROP_FROM_OBSERVATIONS = `
    SELECT *
    FROM crop_stages
    WHERE crop_id=$crop_id AND isDeleted=0
`

export const SELECT_PHENOPHASES_FROM_WEEDS = `
    SELECT id, phenoclass_id, name
    FROM phenophases
    WHERE id=$phenophase_id
`

export const SELECT_PHENOPHASES_BY_CULTURE_FROM_WEEDS = `
    SELECT ph.id, ph.name
    FROM phenophases as ph, crops as cs, cults as cl, phenoclasses as phc
    WHERE cs.id=$crop_id AND cs.cult_id=cl.id AND cl.phenoclass_id=phc.id AND ph.phenoclass_id=phc.id
`

export const SELECT_PHENOPHASES_BY_CROP_FROM_OBSERVATIONS = `
    SELECT phenophase_id
    FROM crop_stages
    WHERE crop_id=$crop_id
    GROUP BY phenophase_id
`
export const SELECT_PHENOPHASES_BY_CROP_FROM_WEEDS = (phenophases_ids: Array<string>) => `
    SELECT id, name
    FROM phenophases
    WHERE id IN (${phenophases_ids.join(',')})
`

export const SELECT_OBSERVATION_BY_ID_FROM_OBSERVATIONS = `
    SELECT *
    FROM observations
    WHERE id=$observation_id
`

export const SELECT_OBSERVATIONS_FROM_OBSERVATIONS = `
    SELECT * 
    FROM observations
    WHERE crop_stage_id=$crop_stage_id AND isDeleted=0
`

export const SELECT_INFECTION_FROM_WEEDS = `
    SELECT *
    FROM infections
    WHERE id=$infection_id
`

export const SELECT_QUARANT_OBJ_FROM_WEEDS = `
    SELECT *
    FROM quarantobj
    WHERE id=$quarantobj_id
`
export const SELECT_OBSERVATIONS_FROM_WEEDS = `
    SELECT ob.id as id, ob.crop_stage_id, ob.infection_id as infection_id, inf.latname as infection, ph.name as phenophase, qo.id as quarantobj_id, qo.latname as quarantobj, ob.place as place, ob.fullness as fullness, ob.note as note, ob.isSync as isSync
    FROM observations as ob
    LEFT JOIN infections as inf ON
    ob.infection_id=inf.id
    LEFT JOIN phenophases as ph ON
    ob.phenophase_id=ph.id
    LEFT JOIN quarantobj as qo ON
    ob.quarantobj_id=qo.id
    WHERE ob.crop_stage_id=$crop_stage_id AND ob.isDeleted=0
`

export const SELECT_QUARANTOBJ_FROM_WEEDS = `
    SELECT id, latname as name
    FROM quarantobj
`

export const SELECT_INFECTIONS_FROM_WEEDS = `
    SELECT inf.id as id, inf.latname as name
    FROM infections as inf
`

export const SELECT_PHENOPHASES_BY_INFECTION_FROM_WEEDS = `
    SELECT ph.id as id, ph.name as name
    FROM phenophases as ph, infections as inf, phenoclasses as phc
    WHERE inf.id=$infection_id AND inf.phenoclass_id=phc.id AND ph.phenoclass_id=phc.id
`

export const SELECT_OBSERVATION_BY_ID_FROM_WEEDS = `
    SELECT ob.id as id, ob.infection_id as infection_id, inf.latname as infection, ph.name as phenophase, ob.place as place, ob.fullness as fullness, ob.note as note, ob.isSync as isSync
    FROM observations as ob
    LEFT JOIN infections as inf ON
    ob.infection_id=inf.id
    LEFT JOIN phenophases as ph ON
    ob.phenophase_id=ph.id
    WHERE ob.id=$observation_id
`

export const SELECT_STAGE_BY_ID_FROM_OBSERVATIONS = `
    SELECT *
    FROM crop_stages
    WHERE id=$id
`

export const SELECT_STAGE_BY_ID_FROM_WEEDS = `
    SELECT cs.id as id, ph.name as name, cs.date as date, cs.phenophase_id as phenophase_id, cs.createdAt as createdAt, cs.isSync as isSync
    FROM crop_stages as cs, phenophases as ph
    WHERE cs.id=$id AND cs.phenophase_id = ph.id
`

export const SELECT_CROP_STAGES_FOR_SYNCHRONIZATION_FROM_OBSERVATIONS = `
    SELECT cs.id as id, cs.date as date, cs.createdAt as createdAt, cs.crop_id as crop_id, cs.phenophase_id as phenophase_id, cs.isDeleted as isDeleted
    FROM crop_stages as cs
    WHERE cs.isSync=0 OR (cs.isSync=1 AND cs.isDeleted=1)
`

export const SELECT_OBSERVATIONS_FOR_SYNCHRONIZATION_FROM_OBSERVATIONS = `
    SELECT id, crop_stage_id, infection_id, phenophase_id, place, fullness, user, note, isDeleted 
    FROM observations
    WHERE isSync=0 OR (isSync=1 AND isDeleted=1)
`

export const SELECT_NOTIFICATIONS_FROM_WEEDS = `
    SELECT id, title, message, createdAt, isRead
    FROM notification
`

export const SELECT_UNREAD_NOTIFICATIONS_COUNT_FROM_WEEDS = `
    SELECT COUNT(*) as count
    FROM notification
    WHERE isRead=0
`


