import React, {useEffect, useState} from 'react';
import Header from "@components/Header/Header";
import {useDataBase} from "@hooks/useDataBase";
import {ICropStage, IStage} from "@interfaces/crops";
import {executeQueryDataBase, saveDataBase} from "@shared/helpersDB";
import {
    SELECT_PHENOPHASES_FROM_WEEDS,
    SELECT_STAGE_BY_ID_FROM_OBSERVATIONS,
    SELECT_STAGE_BY_ID_FROM_WEEDS
} from "@constants/selectQuery";
import {useNavigate, useParams} from "react-router-dom";
import CropStageForm, {StageFormValues} from "@components/Form/CropStageForm/CropStageForm";
import {UPDATE_CROP_STAGE_INTO_OBSERVATIONS} from "@constants/updateQuery";
import {OBSERVATIONS_DATABASE, WEEDS_DATABASE} from "@constants/dataBase";
import {IPhenophase} from "@interfaces/phenophases";

const Stage = () => {

    const {weedsDataBase, observationsDataBase} = useDataBase()
    const {crop_stage_id} = useParams()
    const navigate = useNavigate()

    const [stage, setStage] = useState<IStage | null>(null)

    useEffect(() => {
        if (!weedsDataBase || !observationsDataBase) return;
        const cropStage = executeQueryDataBase<ICropStage>(observationsDataBase, SELECT_STAGE_BY_ID_FROM_OBSERVATIONS, {
            '$id': crop_stage_id || null
        })[0]
        const phenophase = executeQueryDataBase<IPhenophase>(weedsDataBase, SELECT_PHENOPHASES_FROM_WEEDS, {
            '$phenophase_id': cropStage.phenophase_id
        })[0]
        setStage({
            ...cropStage,
            name: phenophase.name
        })
    }, [weedsDataBase]);

    const handleSubmitForm = async (data: StageFormValues) => {
        if (!observationsDataBase) return;
        executeQueryDataBase(observationsDataBase, UPDATE_CROP_STAGE_INTO_OBSERVATIONS, {
            '$date': data.date?.format('YYYY-MM-DD') || '',
            '$phenophase_id': data.phenophase?.id || null,
            '$id': crop_stage_id!
        })
        await saveDataBase(OBSERVATIONS_DATABASE, observationsDataBase)
        navigate(-1)
    }

    return (
        <>
            <Header title={'Стадия посева'}/>
            {stage && <CropStageForm cropStage={stage} onSubmit={handleSubmitForm} buttonTitle={'Обновить'}/>}
        </>
    );
};

export default Stage;