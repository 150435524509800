export const UPDATE_OBSERVATION_INTO_OBSERVATIONS = `
    UPDATE observations
    SET infection_id=$infection_id,
        phenophase_id=$phenophase_id,
        place=$place,
        fullness=$fullness,
        note=$note,
        user=$user,
        isSync=0
    WHERE id=$id
`

export const UPDATE_OBSERVATION_AFTER_SYNCHRONIZATION_INTO_WEEDS = `
    UPDATE observations
    SET isSync=1
    WHERE id IN ($ids)
`

export const UPDATE_CROP_STAGE_INTO_OBSERVATIONS = `
    UPDATE crop_stages
    SET date=$date,
        phenophase_id=$phenophase_id,
        isSync=0
    WHERE id=$id
`

export const UPDATE_CROP_STAGE_AFTER_SYNCHRONIZATION_INTO_WEEDS = `
    UPDATE crop_stages
    SET isSync=1
    WHERE id IN ($ids)
`

export const UPDATE_SYNCHRONIZED_FIELD_CROP_STAGE_INTO_OBSERVATIONS = `
    UPDATE crop_stages
    SET isSync=0
    WHERE id=$crop_stage_id
`

export const UPDATE_READ_NOTIFICATION_INTO_WEEDS = `
    UPDATE notification
    SET isRead=1
    WHERE id=$id
`