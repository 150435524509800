import React, {FC, HTMLAttributes} from 'react';

import styles from './TextAreat.module.scss';
import cn from "classnames";

interface TextAreaProps extends HTMLAttributes<HTMLTextAreaElement>{

}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(({className, ...props}, ref) => {
    return (
        <textarea ref={ref} {...props} className={cn(className, styles.textarea)}/>
    );
});

export default TextArea;