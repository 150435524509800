import React from 'react';
import Header from "@components/Header/Header";
import ObservationForm, {ObservationFormValues} from "@components/Form/ObservationForm/ObservationForm";
import {executeQueryDataBase, saveDataBase} from "@shared/helpersDB";
import {useDataBase} from "@hooks/useDataBase";
import {INSERT_OBSERVATION_INTO_OBSERVATIONS} from "@constants/insertQuery";
import {useNavigate, useParams} from "react-router-dom";
import {OBSERVATIONS_DATABASE, WEEDS_DATABASE} from "@constants/dataBase";
import {UPDATE_SYNCHRONIZED_FIELD_CROP_STAGE_INTO_OBSERVATIONS} from "@constants/updateQuery";
import {v4 as uuid} from 'uuid'

const NewObservation = () => {

    const {observationsDataBase} = useDataBase()
    const {crop_stage_id} = useParams()
    const navigate = useNavigate()

    const handleSubmit = async (data: ObservationFormValues) => {
        if (!observationsDataBase) return;
        const {
            infection,
            phenophase,
            place,
            fullness,
            note
        } = data
        executeQueryDataBase(observationsDataBase, INSERT_OBSERVATION_INTO_OBSERVATIONS, {
            '$id': uuid(),
            '$crop_stage_id': crop_stage_id || '',
            '$infection_id': infection?.id || null,
            '$phenophase_id': phenophase?.id || null,
            '$user': 'Иванов Иван',
            '$place': place,
            '$fullness': fullness,
            '$note': note
        })
        executeQueryDataBase(observationsDataBase, UPDATE_SYNCHRONIZED_FIELD_CROP_STAGE_INTO_OBSERVATIONS, {
            '$crop_stage_id': crop_stage_id || ''
        })
        await saveDataBase(OBSERVATIONS_DATABASE, observationsDataBase)
        navigate(-1)
    }

    return (
        <>
            <Header title={'Новое наблюдение'} />
            <ObservationForm onSubmit={handleSubmit}/>
        </>
    );
};

export default NewObservation;