import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Header from "@components/Header/Header";
import {useDataBase} from "@hooks/useDataBase";
import {IObservation, IViewObservation} from "@interfaces/observation";
import ObservationForm, {ObservationFormValues} from "@components/Form/ObservationForm/ObservationForm";
import {executeQueryDataBase, saveDataBase} from "@shared/helpersDB";
import {
    SELECT_INFECTION_FROM_WEEDS,
    SELECT_OBSERVATION_BY_ID_FROM_OBSERVATIONS,
    SELECT_PHENOPHASES_FROM_WEEDS
} from "@constants/selectQuery";
import {UPDATE_OBSERVATION_INTO_OBSERVATIONS, UPDATE_SYNCHRONIZED_FIELD_CROP_STAGE_INTO_OBSERVATIONS} from "@constants/updateQuery";
import {OBSERVATIONS_DATABASE, WEEDS_DATABASE} from "@constants/dataBase";
import {IPhenophase} from "@interfaces/phenophases";
import {IInfection} from "@interfaces/infection";

const Observation = () => {

    const {weedsDataBase, observationsDataBase} = useDataBase()
    const {crop_stage_id, observation_id} = useParams()
    const navigate = useNavigate()

    const [observation, setObservation] = useState<IViewObservation | null>(null)

    useEffect(() => {
        if (!weedsDataBase || !observationsDataBase) return;
        const observation = executeQueryDataBase<IObservation>(observationsDataBase, SELECT_OBSERVATION_BY_ID_FROM_OBSERVATIONS, {
            '$observation_id': observation_id!
        })[0]
        const phenophase = executeQueryDataBase<IPhenophase>(weedsDataBase, SELECT_PHENOPHASES_FROM_WEEDS, {
            '$phenophase_id': observation.phenophase_id
        })[0]
        const infection = executeQueryDataBase<IInfection>(weedsDataBase, SELECT_INFECTION_FROM_WEEDS, {
            '$infection_id': observation.infection_id
        })[0]
        setObservation({
            ...observation,
            phenophase: phenophase.name,
            infection: infection.latname
        })
    }, [weedsDataBase, observationsDataBase]);

    const handleSubmitForm = async (data: ObservationFormValues) => {
        if (!observationsDataBase) return;
        executeQueryDataBase(observationsDataBase, UPDATE_OBSERVATION_INTO_OBSERVATIONS, {
            '$id': observation_id!,
            '$infection_id': data.infection?.id || null,
            '$phenophase_id': data.phenophase?.id || null,
            '$place': data.place || '',
            '$user': 'Иванов Иван',
            '$fullness': data.fullness || '',
            '$note': data.note || ''
        })
        executeQueryDataBase(observationsDataBase, UPDATE_SYNCHRONIZED_FIELD_CROP_STAGE_INTO_OBSERVATIONS, {
            '$crop_stage_id': crop_stage_id || ''
        })
        await saveDataBase(OBSERVATIONS_DATABASE, observationsDataBase)
        navigate(-1)
    }

    return (
        <>
            <Header title={'Наблюдение'} />
            {observation && <ObservationForm onSubmit={handleSubmitForm} observation={observation} buttonTitle={'Обновить'}/>}
        </>
    );
};

export default Observation;