import React, {FC} from 'react';
import Modal, {ModalProps} from "@components/Modal/Modal";

import styles from './ConfirmationModal.module.scss'
import Button from "@components/Button/Button";
import cn from "classnames";
interface ConfirmationModalProps extends ModalProps {
    title: string
    subtitle: string
    onResolve: () => void
    onReject: () => void
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({isOpen, onClose, title, subtitle, onReject, onResolve}) => {

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className={styles.wrapper}>
                <h2>{title}</h2>
                <p>{subtitle}</p>
                <div className={styles.wrapperButtons}>
                    <Button className={cn(styles.btn, styles.resolve)} onClick={onResolve}>Да</Button>
                    <Button className={cn(styles.btn, styles.reject)} onClick={onReject}>Нет</Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;