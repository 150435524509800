import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const StyledAccordion = styled(MuiAccordion)(() => ({
    borderRadius: '10px',
    boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.25)',
    '&:last-child': {
        borderRadius: '10px',
    },
    '&:first-of-type': {
        borderRadius: '10px',
        boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.25)',
    },
    '&:before': {
        display: 'none',
    },
    '&.Mui-expanded': {
        width: '100%',
        borderRadius: '10px',
        margin: 0
    },
}));

export const StyledAccordionSummary = styled(MuiAccordionSummary)(() => ({
    borderBottom: '1px solid transparent',
    borderRadius: '10px',
    transition: 'border-radius 0.6s',
    marginBottom: -1,
    minHeight: 56,
    '&.Mui-expanded': {
        borderRadius: '10px 10px 0px 0px',
        transition: 'border-radius 0.1s',
        minHeight: 56,
    },
}));

export const StyledAccordionSummaryGreenExpand = styled(MuiAccordionSummary)(() => ({
    borderBottom: '1px solid transparent',
    borderRadius: '10px',
    transition: 'border-radius 0.6s',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
        borderRadius: '10px 10px 0px 0px',
        transition: 'border-radius 0.1s',
        minHeight: 56,
        backgroundColor: '#73C120',
        color: 'white'
    },
}));

export const StyledAccordionDetails = styled(MuiAccordionDetails)(() => ({
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column'
}))