import React, {FC, HTMLAttributes, PropsWithChildren} from 'react';

import styles from './Card.module.scss'
import cn from "classnames";

export interface CardProps extends PropsWithChildren, HTMLAttributes<HTMLDivElement> {

}

const Card: FC<CardProps> = ({children, className, ...props}) => {
    return (
        <div className={cn(styles.card, className)} {...props}>
            {children}
        </div>
    );
};

export default Card;