import React from 'react';
import DataBaseProvider from "@providers/DataBaseProvider";
import SQLProvider from "@providers/SQLProvider";
import {Outlet} from "react-router-dom";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'

import styles from './root.module.scss'

import 'moment/locale/ru'
import AlertWrapper from "@components/AlertWrapper/AlertWrapper";
import AlertProvider from "@providers/AlertProvider";
import NotificationProvider from "@providers/NotificationProvider";
import SocketProvider from '@app/providers/SocketProvider';

const Root = () => {
    return (
        <AlertProvider>
            <SQLProvider>
                <DataBaseProvider>
                    {/*<NotificationProvider>*/}
                        {/*<SocketProvider>*/}
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='ru'>
                                <main className={styles.wrap}>
                                    <AlertWrapper />
                                    <Outlet />
                                </main>
                            </LocalizationProvider>
                        {/*</SocketProvider>*/}
                   {/* </NotificationProvider>*/}
                </DataBaseProvider>
            </SQLProvider>
        </AlertProvider>
    );
};

export default Root;