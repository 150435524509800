import React, {FC} from 'react';
import Accordion from "@components/Accordion/Accordion";
import {IObservation, IViewObservation} from "@interfaces/observation";
import ObservationBody from "@components/ObservationCard/ObservationBody/ObservationBody";
import cn from "classnames";
import HeaderWithMenu from "@components/HeaderWithMenu/HeaderWithMenu";

import styles from './ObservationCard.module.scss'

interface ObservationProps {
    observation: IViewObservation
    onDelete?: (observation: IViewObservation) => void
    onEdit?: (observation: IViewObservation) => void
}

const ObservationCard: FC<ObservationProps> = ({observation, onEdit, onDelete}) => {

    const {isSync, infection, id} = observation

    return (
        <Accordion
            headerClassName={cn({[styles.greenHeader]: !isSync})}
            header={<HeaderWithMenu
                title={infection}
                isMarked={isSync}
                onDelete={onDelete ? () => onDelete(observation) : undefined}
                onEdit={onEdit ? () => onEdit(observation) : undefined}
            />}
            expanded={true}
        >
            <ObservationBody observation={observation} />
        </Accordion>
    );
};

export default ObservationCard;