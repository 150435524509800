import React, {FC, MouseEventHandler} from 'react';
import Card, {CardProps} from "@components/Card/Card";
import {IStage} from "@interfaces/crops";

import styles from './CropStageCard.module.scss'
import moment from "moment";
import cn from "classnames";
import {createSearchParams, useNavigate} from "react-router-dom";
import {IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

export interface CropStageCardProps extends CardProps {
    stage: IStage
    onEdit?: (stage: IStage) => void
    onDelete?: (stage: IStage) => void
}

const CropStageCard: FC<CropStageCardProps> = ({stage, className, onEdit, onDelete, ...props}) => {

    const handleClickDelete: MouseEventHandler<HTMLButtonElement>  = (event) => {
        event.preventDefault()
        event.stopPropagation()
        onDelete && onDelete(stage)
    }

    const handleClickEdit: MouseEventHandler<HTMLButtonElement>  = (event) => {
        event.preventDefault()
        event.stopPropagation()
        onEdit && onEdit(stage)
    }

    return (
        <Card className={cn(className, {[styles.unsync]: !stage.isSync})} {...props}>
            <div className={styles.card}>
                <p className={styles.title}>{stage.name}</p>
                <p className={styles.title}>{moment(stage.date).format('DD.MM.YYYY')}</p>
            </div>
            <div className={styles.menu}>
                {onEdit &&
                    <IconButton aria-label="edit" sx={{color: stage.isSync ?  'grey' : 'white'}} size="small"
                                onClick={handleClickEdit}>
                        <EditIcon/>
                    </IconButton>
                }
                {onDelete &&
                    <IconButton aria-label="delete" sx={{color: stage.isSync ?  'grey' : 'white'}} size="small"
                                onClick={handleClickDelete}>
                        <DeleteIcon/>
                    </IconButton>
                }
            </div>
        </Card>
    );
};

export default CropStageCard;