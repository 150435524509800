import React, {FC} from 'react';

import styles from './Indicators.module.scss'
import {SuccessIndicator} from "@components/Indicators/SuccessIndicator/SuccessIndicator";
import {FailedIndicator} from "@components/Indicators/FailedIndicator/FailedIndicator";
import {LoadingIndicator} from "@components/Indicators/LoadingIndicator/LoadingIndicator";

export interface IndicatorProps {
    type: 'loading' | 'success' | 'failed'
    title: string
    progress?: number
}

const Indicator: FC<IndicatorProps> = ({type, title, progress}) => {

    return (
        <div className={styles.wrapper}>
            <div style={{width: '40px', marginRight: '10px'}}>
                {type === 'success' && <SuccessIndicator />}
                {type === 'failed' && <FailedIndicator />}
                {type === 'loading' && <LoadingIndicator progress={progress}/>}
            </div>
            <p>{title}</p>
        </div>
    );
};

export default Indicator;