import React, {FC} from 'react';
import {INotification} from "@interfaces/notification";
import Accordion from "@components/Accordion/Accordion";
import moment from 'moment';

import styles from './NotificationCard.module.scss';
import cn from "classnames";

interface NotificationCardProps {
    notification: INotification
    onExpand: (notification: INotification) => void
    expanded?: boolean
}

const NotificationCard: FC<NotificationCardProps> = ({notification, onExpand, expanded = false}) => {

    const handleClickExpand = () => {
        onExpand(notification)
    }

    return (
        <Accordion
            headerClassName={cn({[styles.greenHeader]: !notification.isRead})}
            expanded={expanded}
            header={
                <div className={styles.header}>
                    <p>{notification.title}</p>
                    <p>{moment(notification.createdAt).format('DD.MM.YYYY HH:mm')}</p>
                </div>
            }
            onClick={handleClickExpand}
        >
            <p>{notification.message}</p>
        </Accordion>
    );
};

export default NotificationCard;