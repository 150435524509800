import React, {FC, useEffect, useState} from 'react';
import {Named} from "@app/interfaces";
import {Controller, useForm} from "react-hook-form";
import {useDataBase} from "@hooks/useDataBase";
import {executeQueryDataBase} from "@shared/helpersDB";
import {
    SELECT_INFECTIONS_FROM_WEEDS,
    SELECT_PHENOPHASES_BY_INFECTION_FROM_WEEDS
} from "@constants/selectQuery";
import Autocomplete from "@components/Autocomplete/Autocomplete";
import Input from "@components/Input/Input";
import TextArea from "@components/TextArea/TextArea";
import Button from "@components/Button/Button";
import {IObservation, IViewObservation} from "@interfaces/observation";

export interface ObservationFormValues {
    infection: Named | null
    phenophase: Named | null
    place: string | null
    fullness: string
    note: string
}

interface ObservationFormProps {
    observation?: IViewObservation
    onSubmit: (data: ObservationFormValues) => void
    buttonTitle?: string
}

const PLACES = ['Край', 'Основная часть']

const ObservationForm: FC<ObservationFormProps> = ({onSubmit, observation, buttonTitle = 'Добавить' }) => {

    const {weedsDataBase} = useDataBase()
    const {control, register, formState: {errors}, setValue,  handleSubmit, watch} = useForm<ObservationFormValues>({
        defaultValues: {
            infection: observation ? {id: observation.infection_id, name: observation.infection} : null,
            phenophase: observation ? {id: observation.phenophase_id, name: observation.phenophase} : null,
            place: observation ? observation.place : null,
            fullness: observation ? observation.fullness : '',
            note: observation && observation.note ? observation.note : ''
        }
    })

    const [infections, setInfections] = useState<Array<Named>>([])
    const [phenophases, setPhenophases] = useState<Array<Named>>([])

    const watchedInfection = watch('infection')

    useEffect(() => {
        if (!weedsDataBase) return;
        setInfections(executeQueryDataBase(weedsDataBase, SELECT_INFECTIONS_FROM_WEEDS))
    }, [weedsDataBase]);

    useEffect(() => {
        if (!weedsDataBase || !watchedInfection) return;
        setPhenophases(executeQueryDataBase(weedsDataBase, SELECT_PHENOPHASES_BY_INFECTION_FROM_WEEDS, {
            '$infection_id': watchedInfection.id
        }))
    }, [weedsDataBase, watchedInfection]);

    return (
        <form className="default-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-field">
                <label htmlFor="infection" className="form-label">Агент заражения<span className='required'>*</span></label>
                <Controller
                    control={control}
                    name={'infection'}
                    rules={{
                        required: 'Поле обязательно для заполнения!'
                    }}
                    render={({field: {value, onChange}, fieldState: {error}}) => <>
                        <Autocomplete
                            id={'infection'}
                            placeholder={'Выберите агента заражения'}
                            options={infections}
                            error={!!error}
                            value={value}
                            getOptionLabel={option => option.name}
                            onChange={(event, value) => {
                                onChange(value)
                                setValue('phenophase', null)
                            }}
                        />
                        {error && <span className='form-error'>{error.message}</span>}
                    </>}
                />
            </div>
            <div className="form-field">
                <label htmlFor="phenophase" className="form-label">Стадия<span className='required'>*</span></label>
                <Controller
                    control={control}
                    name={'phenophase'}
                    rules={{
                        required: 'Поле обязательно для заполнения!'
                    }}
                    render={({field: {value, onChange}, fieldState: {error}}) => <>
                        <Autocomplete
                            id={'phenophase'}
                            placeholder={'Выберите стадию'}
                            options={phenophases}
                            readOnly={!watchedInfection}
                            error={!!error}
                            value={value}
                            getOptionLabel={option => option.name}
                            onChange={(event, value) => {
                                onChange(value)
                            }}
                        />
                        {error && <span className='form-error'>{error.message}</span>}
                    </>}
                />
            </div>
            <div className="form-field">
                <label htmlFor="place" className="form-label">Место<span className='required'>*</span></label>
                <Controller
                    control={control}
                    name={'place'}
                    rules={{
                        required: 'Поле обязательно для заполнения!'
                    }}
                    render={({field: {value, onChange}, fieldState: {error}}) => <>
                        <Autocomplete
                            id={'place'}
                            placeholder={'Выберите место'}
                            options={PLACES}
                            error={!!error}
                            value={value}
                            onChange={(event, value) => {
                                onChange(value)
                            }}
                        />
                        {error && <span className='form-error'>{error.message}</span>}
                    </>}
                />
            </div>
            <div className="form-field">
                <label htmlFor="fullness" className="form-label">Обилие<span className='required'>*</span></label>
                <Input {...register('fullness', {required: 'Поле обязательно для заполнения!'})} id="fullness" placeholder="Введите обилие"/>
                {errors.fullness && <span className='form-error'>{errors.fullness.message}</span>}
            </div>
            <div className="form-field">
                <label htmlFor="note" className="form-label">Примечание</label>
                <TextArea {...register('note')} id="note" placeholder="Введите примечание"/>
            </div>
            <Button>{buttonTitle}</Button>
        </form>
    );
};

export default ObservationForm;