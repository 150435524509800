import React, {useEffect, useState} from 'react';
import Indicator, {IndicatorProps} from "@components/Indicators/Indicator";
import {useDataBase} from "@hooks/useDataBase";
import {executeQueryDataBase, saveDataBase} from "@shared/helpersDB";
import {IStage, IStageForSynchronization} from "@interfaces/crops";
import {
    SELECT_CROP_STAGES_FOR_SYNCHRONIZATION_FROM_OBSERVATIONS,
    SELECT_OBSERVATIONS_FOR_SYNCHRONIZATION_FROM_OBSERVATIONS
} from "@constants/selectQuery";
import {IObservation, IObservationForSynchronization} from "@interfaces/observation";
import apiService from "@services/ApiService";
import {HOST} from "@shared/enviroment";
import {TRANSACTION_UPDATE_CROP_STAGES_TABLE, TRANSACTION_UPDATE_OBSERVATIONS_TABLE} from "@constants/transactionQuery";
import {OBSERVATIONS_DATABASE} from "@constants/dataBase";

const SynchronizationObservation = () => {

    const {observationsDataBase} = useDataBase()

    const [synchronizationState, setSynchronizationState] = useState<IndicatorProps>({
        type: 'loading',
        title: 'Сбор данных наблюдений'
    })

    useEffect(() => {
        if (!observationsDataBase) return;
        const unsyncCropStages = executeQueryDataBase<IStageForSynchronization>(observationsDataBase, SELECT_CROP_STAGES_FOR_SYNCHRONIZATION_FROM_OBSERVATIONS)
        const unsyncObservations = executeQueryDataBase<IObservationForSynchronization>(observationsDataBase, SELECT_OBSERVATIONS_FOR_SYNCHRONIZATION_FROM_OBSERVATIONS)
        setSynchronizationState({type: 'loading', title: 'Отправка данных о наблюдениях на сервер'})
        apiService.post(HOST + '/sync', {cropStages: unsyncCropStages, observations: unsyncObservations})
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Произошла ошибка при запросе к серверу. Попробуйте позже.')
                }
            })
            .then(({cropStages, observations}: { cropStages: Array<IStage>, observations: Array<IObservation> }) => {
                try {
                    if (cropStages.length !== 0) {
                        console.log('crop_stages: ', TRANSACTION_UPDATE_CROP_STAGES_TABLE(cropStages))
                        observationsDataBase.run(TRANSACTION_UPDATE_CROP_STAGES_TABLE(cropStages))
                    }
                    if (observations.length !== 0) {
                        console.log('observations: ', TRANSACTION_UPDATE_OBSERVATIONS_TABLE(observations))
                        observationsDataBase.run(TRANSACTION_UPDATE_OBSERVATIONS_TABLE(observations))
                    }
                    saveDataBase(OBSERVATIONS_DATABASE, observationsDataBase)
                        .then(() => setSynchronizationState({
                            type: 'success',
                            title: 'База данных наблюдений успешно синхронизированна'
                        }))
                } catch (error) {
                    console.error(error)
                    throw new Error('Произошла ошибка при обновлении базы данных наблюдений')
                }
            })
            .catch((error: Error) => {
                console.error(error)
                setSynchronizationState({type: 'failed', title: error.message})
            })
    }, [observationsDataBase]);

    return (
        <Indicator type={synchronizationState.type} title={synchronizationState.title}/>
    );
};

export default SynchronizationObservation;