import React, {useEffect, useState} from 'react';
import Header from "@components/Header/Header";
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import Autocomplete from "@components/Autocomplete/Autocomplete";
import Button from "@components/Button/Button";
import {Named} from "@interfaces/index";
import {executeQueryDataBase} from "@shared/helpersDB";
import {
    SELECT_CULT_TYPES_FROM_WEEDS,
    SELECT_CULTURES_FROM_WEEDS,
    SELECT_DISTRICTS_FROM_WEEDS,
    SELECT_REGIONS_FROM_WEEDS,
    SELECT_YEAR_FROM_WEEDS
} from "@constants/selectQuery";
import {useDataBase} from "@hooks/useDataBase";
import {createSearchParams, useNavigate} from "react-router-dom";

interface FormValues {
    year: string | null
    region: Named | null
    district: Named | null
    culture: Named | null
    cultureType: Named | null
}

const SearchCrops = () => {

    const {control, handleSubmit, resetField, watch} = useForm<FormValues>({
        defaultValues: {
            year: null,
            culture: null,
            cultureType: null,
            district: null,
            region: null
        }
    })
    const navigate = useNavigate();

    const {weedsDataBase} = useDataBase()
    const [years, setYears] = useState<Array<string>>([])
    const [regions, setRegions] = useState<Array<Named>>([])
    const [districts, setDistricts] = useState<Array<Named>>([])
    const [cultures, setCultures] = useState<Array<Named>>([])
    const [cultureTypes, setCultureTypes] = useState<Array<Named>>([])

    const watchedYear = watch('year')
    const watchedRegion = watch('region')
    const watchedDistrict = watch('district')
    const watchedCulture = watch('culture')

    useEffect(() => {
        if (!weedsDataBase) return;
        setYears(executeQueryDataBase<{
            id: string,
            year: string
        }>(weedsDataBase, SELECT_YEAR_FROM_WEEDS).map(value => value.year))
    }, [weedsDataBase]);

    useEffect(() => {
        if (!weedsDataBase || !watchedYear) return;
        setRegions(executeQueryDataBase<Named>(weedsDataBase, SELECT_REGIONS_FROM_WEEDS, {'$year': watchedYear}))
    }, [weedsDataBase, watchedYear])

    useEffect(() => {
        if (!weedsDataBase || !watchedRegion) return;
        setDistricts(executeQueryDataBase<Named>(weedsDataBase, SELECT_DISTRICTS_FROM_WEEDS, {
            '$year': watchedYear!,
            '$region_id': watchedRegion.id
        }))
    }, [weedsDataBase, watchedRegion]);

    useEffect(() => {
        if (!weedsDataBase || !watchedDistrict) return;
        setCultures(executeQueryDataBase<Named>(weedsDataBase, SELECT_CULTURES_FROM_WEEDS, {
            '$year': watchedYear!,
            '$region_id': watchedRegion!.id,
            '$district_id': watchedDistrict.id,
        }))
    }, [weedsDataBase, watchedDistrict])

    useEffect(() => {
        if (!weedsDataBase || !watchedCulture) return;
        setCultureTypes(executeQueryDataBase<Named>(weedsDataBase, SELECT_CULT_TYPES_FROM_WEEDS, {
            '$year': watchedYear!,
            '$region_id': watchedRegion!.id,
            '$district_id': watchedDistrict!.id,
            '$cult_id': watchedCulture.id
        }))
    }, [weedsDataBase, watchedCulture]);

    const handleSubmitForm: SubmitHandler<FormValues> = (data) => {
        navigate(`/crops?` + createSearchParams({
            year: data.year || '',
            region: data.region?.id.toString() || '',
            district: data.district?.id.toString() || '',
            culture: data.culture?.id.toString() || '',
            cultureType: data.cultureType?.id.toString() || 'NULL'
        }))
    }

    const resetFields = (fields: Array<keyof FormValues>) => {
        fields.forEach(field => {
            resetField(field)
        })
    }

    return (
        <>
            <Header title={'Поиск посева'}/>
            <form className='default-form' onSubmit={handleSubmit(handleSubmitForm)}>
                <div className='form-field'>
                    <label htmlFor="year" className='form-label'>Год<span className='required'>*</span></label>
                    <Controller
                        control={control}
                        name={'year'}
                        rules={{required: 'Поле обязательно для заполнения!'}}
                        render={({field: {value, onChange}, fieldState: {error}}) =>
                            <>
                                <Autocomplete
                                    id='year'
                                    options={years}
                                    error={!!error}
                                    value={value}
                                    onChange={(event, value) => {
                                        onChange(value)
                                        resetFields(['region', 'district', 'culture', 'cultureType'])
                                    }}
                                    placeholder={'Выберите год'}
                                />
                                {error && <span className='form-error'>{error.message}</span>}
                            </>
                        }
                    />
                </div>
                <div className='form-field'>
                    <label htmlFor="region" className='form-label'>Регион<span className='required'>*</span></label>
                    <Controller
                        control={control}
                        name={'region'}
                        rules={{required: 'Поле обязательно для заполнения!'}}
                        render={({field: {value, onChange}, fieldState: {error}}) =>
                            <>
                                <Autocomplete
                                    id='region'
                                    options={regions}
                                    error={!!error}
                                    value={value}
                                    readOnly={!watchedYear}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => {
                                        onChange(value)
                                        resetFields(['district', 'culture', 'cultureType'])
                                    }}
                                    placeholder={'Выберите регион'}
                                />
                                {error && <span className='form-error'>{error.message}</span>}
                            </>
                        }
                    />
                </div>
                <div className='form-field'>
                    <label htmlFor="district" className='form-label'>Район<span className='required'>*</span></label>
                    <Controller
                        control={control}
                        name={'district'}
                        rules={{required: 'Поле обязательно для заполнения!'}}
                        render={({field: {value, onChange}, fieldState: {error}}) =>
                            <>
                                <Autocomplete
                                    id='district'
                                    options={districts}
                                    error={!!error}
                                    value={value}
                                    readOnly={!watchedRegion}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => {
                                        onChange(value)
                                        resetFields(['culture', 'cultureType'])
                                    }}
                                    placeholder={'Выберите район'}
                                />
                                {error && <span className='form-error'>{error.message}</span>}
                            </>
                        }
                    />
                </div>
                <div className='form-field'>
                    <label htmlFor="culture" className='form-label'>Культура<span className='required'>*</span></label>
                    <Controller
                        control={control}
                        name={'culture'}
                        rules={{required: 'Поле обязательно для заполнения!'}}
                        render={({field: {value, onChange}, fieldState: {error}}) =>
                            <>
                                <Autocomplete
                                    id='culture'
                                    options={cultures}
                                    error={!!error}
                                    value={value}
                                    readOnly={!watchedDistrict}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => {
                                        onChange(value)
                                        resetFields(['cultureType'])
                                    }}
                                    placeholder={'Выберите культуру'}
                                />
                                {error && <span className='form-error'>{error.message}</span>}
                            </>
                        }
                    />
                </div>
                <div className='form-field'>
                    <label htmlFor="cultureType" className='form-label'>Тип культуры</label>
                    <Controller
                        control={control}
                        name={'cultureType'}
                        render={({field: {value, onChange}, fieldState: {error}}) =>
                            <>
                                <Autocomplete
                                    id='cultureType'
                                    options={cultureTypes}
                                    error={!!error}
                                    value={value}
                                    readOnly={!watchedCulture}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => onChange(value)}
                                    placeholder={'Выберите тип культуры'}
                                />
                            </>
                        }
                    />
                </div>
                <Button>Найти</Button>
            </form>
        </>
    );
};

export default SearchCrops;