export const INSERT_CROP_STAGE_INTO_OBSERVATIONS = `
    INSERT INTO crop_stages (id, crop_id, date, createdAt, phenophase_id, isSync, isDeleted) 
    VALUES ($id, $crop_id, $date, $createdAt, $phenophase_id, 0, 0);
`

export const INSERT_OBSERVATION_INTO_OBSERVATIONS = `
    INSERT INTO observations (id, crop_stage_id, infection_id, phenophase_id, place, fullness, user, note, isSync, isDeleted)
    VALUES ($id, $crop_stage_id, $infection_id, $phenophase_id, $place, $fullness, $user, $note, 0, 0);
`

export const INSERT_NOTIFICATION_INTO_WEEDS = `
    INSERT INTO notification (id, title, message, isRead, date)
    VALUES ($id, $title, $message, 0, $date)
`