import React from 'react';
import {Popper, useAutocomplete, UseAutocompleteProps} from "@mui/base";
import { unstable_useForkRef as useForkRef } from '@mui/utils';
import { Input } from '@mui/base/Input';
import { Button } from '@mui/base/Button';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import cn from 'classnames'

import styles from './Autocomplete.module.scss'
import {isAutocompleteGroupedOption} from "@components/Autocomplete/helpers";

interface AutocompleteProps<Value, Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined> extends UseAutocompleteProps<Value, false, DisableClearable, false> {
    placeholder?: string
    error?: boolean
}

interface WithForwardRefType extends React.FC<AutocompleteProps<unknown, boolean | undefined, boolean | undefined, boolean | undefined>>  {
    <Value, Multiple extends boolean | undefined,
        DisableClearable extends boolean | undefined,
        FreeSolo extends boolean | undefined>(props: AutocompleteProps<Value, false, DisableClearable, false>): ReturnType<React.FC<AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>>>
}

const Autocomplete: WithForwardRefType = React.forwardRef(
    (props, ref: React.ForwardedRef<HTMLDivElement>) => {

        const {
            disableClearable ,
            disabled ,
            readOnly ,
            options,
            placeholder,
            getOptionLabel,
            error = false,
            ...other
        } = props;

        const {
            getRootProps,
            getInputProps,
            getPopupIndicatorProps,
            getClearProps,
            getListboxProps,
            getOptionProps,
            dirty,
            id,
            popupOpen,
            focused,
            anchorEl,
            setAnchorEl,
            groupedOptions,
        } = useAutocomplete({
            ...props,
            componentName: 'Autocomplete',
        });

        const hasClearIcon = !disableClearable && !disabled && dirty && !readOnly;

        const rootRef = useForkRef(ref, setAnchorEl);

        return (
            <div style={{width: '100%'}}>
                <div
                    {...getRootProps(other)}
                    ref={rootRef}
                    className={cn(
                        styles.root,
                        {[styles.focused]: focused},
                        {[styles.open]: popupOpen},
                        {[styles.error]: error},
                        {[styles.readOnly]: readOnly}
                    )}
                >
                    <Input
                        id={id}
                        ref={setAnchorEl}
                        disabled={disabled}
                        readOnly={readOnly}
                        placeholder={placeholder}
                        slotProps={{
                            root: {
                                className: styles.input_root,
                            },
                            input: {
                                className: styles.input,
                                ...getInputProps(),
                            },
                        }}
                    />
                    {hasClearIcon && (
                        <Button
                            {...getClearProps()}
                            className={cn(styles.indicator, styles.indicator_clear)}
                        >
                            <ClearIcon />
                        </Button>
                    )}
                    <Button
                        {...getPopupIndicatorProps()}
                        className={cn(
                            styles.indicator,
                            styles.indicator_popup,
                            {[styles.popupOpen]: popupOpen}
                        )}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </div>
                {anchorEl &&
                    <Popper
                        open={popupOpen}
                        disablePortal
                        anchorEl={anchorEl}
                        slotProps={{
                            root: { className: styles.popper },
                        }}
                    >
                        <ul {...getListboxProps()} className={styles.listBox}>
                            {groupedOptions.map((option, index) => {
                                if (typeof option === 'string' || typeof option === 'number') {
                                    const optionProps = getOptionProps({ option, index });
                                    return (
                                        <li {...optionProps} className={styles.option}>
                                            {option}
                                        </li>
                                    );
                                } else if (!isAutocompleteGroupedOption(option)) {
                                    const optionProps = getOptionProps({ option, index });
                                    return (
                                        <li {...optionProps} className={styles.option}>
                                            {getOptionLabel && getOptionLabel(option)}
                                        </li>
                                    );
                                }
                            })}

                            {groupedOptions.length === 0 && (
                                <li className={styles.noOptions}>Нет данных</li>
                            )}
                        </ul>
                    </Popper>}
            </div>
        );
    });

export default Autocomplete;