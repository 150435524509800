import React, {useEffect, useState} from 'react';
import Header from "@components/Header/Header";
import {useDataBase} from "@hooks/useDataBase";
import {executeQueryDataBase, saveDataBase} from "@shared/helpersDB";
import {INSERT_CROP_STAGE_INTO_OBSERVATIONS} from '@constants/insertQuery'
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import moment, {Moment} from "moment";
import {OBSERVATIONS_DATABASE, WEEDS_DATABASE} from "@constants/dataBase";
import CropStageForm, {StageFormValues} from "@components/Form/CropStageForm/CropStageForm";
import {v4 as uuid} from 'uuid'


const NewCropStage = () => {


    const {observationsDataBase} = useDataBase()
    const {crop_id} = useParams()

    const navigate = useNavigate()

    const handleSubmitForm= async (data: StageFormValues) => {
        if (!observationsDataBase) return;
        console.log(data.date?.format('YYYY-MM-DD'))
        executeQueryDataBase(observationsDataBase, INSERT_CROP_STAGE_INTO_OBSERVATIONS, {
            '$id': uuid(),
            '$crop_id': crop_id ? +crop_id : '',
            '$date': data.date ? data.date.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
            '$phenophase_id': data.phenophase!.id,
            '$createdAt': moment().toISOString()
        })
        await saveDataBase(OBSERVATIONS_DATABASE, observationsDataBase)
        navigate(-1)
    }

    return (
        <>
            <Header title={'Новая стадия посева'}/>
            <CropStageForm onSubmit={handleSubmitForm} />
        </>
    );
};

export default NewCropStage;