import CircularProgress from "@mui/material/CircularProgress";
import React, {FC} from "react";
import {IndicatorProps} from "@interfaces/ui";

interface LoadingIndicatorProps {
    progress?: number
}

export const LoadingIndicator: FC<LoadingIndicatorProps> = ({progress}) => {
    return (
        <>
            {progress ?
                <CircularProgress sx={{color: '#73C120', width: '100%'}} size={40} thickness={2} value={progress}
                                  variant="determinate"/> :
                <CircularProgress sx={{color: '#73C120', width: '100%'}} size={40} thickness={2}/>
            }
        </>

    )
}