import React, {FC, MouseEventHandler, useEffect, useState} from 'react';
import {StyledAccordion, StyledAccordionDetails, StyledAccordionSummary} from "@components/Accordion/AccordionStyles";
import ExpandMore from "@mui/icons-material/ExpandMore"
import {IconButton} from "@mui/material";

interface AccordionProps {
    style?: React.CSSProperties
    header: React.ReactNode
    children: React.ReactNode | React.ReactNode[]
    onClick?: () => void
    expanded?: boolean
    headerClassName?: string
}

const Accordion: FC<AccordionProps> = ({header, expanded= false, onClick, style, headerClassName, children}) => {

    const [expd, setExpanded] = useState(expanded)

    useEffect(() => {
        setExpanded(expanded)
    }, [expanded])

    const handleClickExpand: MouseEventHandler<HTMLButtonElement> = (event) => {
        onClick && onClick()
        setExpanded(prev => !prev)
    }

    return (
        <StyledAccordion
            style={{
            ...style
        }}
            expanded={expd}
        >
            <StyledAccordionSummary
                className={headerClassName}
                expandIcon={
                    <IconButton aria-label="expand" size="small" onClick={handleClickExpand}>
                        <ExpandMore/>
                    </IconButton>
                }
            >
                {header}
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                {children}
            </StyledAccordionDetails>
        </StyledAccordion>
    );
};

export default Accordion;