import React, {ComponentType, FC, useEffect, useState} from 'react';
import {DataBaseStateType} from "@interfaces/database";
import localforage from "localforage";
import apiService from "@services/ApiService";
import {HOST} from "@shared/enviroment";

interface DeterminateProps {
    dataBaseName: string
}

export function withStateDeterminate(IndicatorComponent: ComponentType<{state: DataBaseStateType | null}>): FC<DeterminateProps> {

    const WrapperComponent: FC<DeterminateProps> = ({dataBaseName}) => {

        const [state, setState] = useState<DataBaseStateType | null>(null)

        useEffect(() => {
            (async () => {
                const dataBase: Uint8Array | null = await localforage.getItem(dataBaseName + '_sqlite')
                if (!dataBase) {
                    setState(DataBaseStateType.EMPTY)
                    return;
                }

                const currentLengthDataBase = await localforage.getItem(dataBaseName + '_current_length')
                if (!currentLengthDataBase) {
                    setState(DataBaseStateType.EMPTY)
                    return;
                }

                const hashDataBase = await localforage.getItem(dataBaseName + '_hash')
                if (!hashDataBase) {
                    setState(DataBaseStateType.EMPTY)
                    return;
                }

                const lengthDataBase = await localforage.getItem(dataBaseName + '_length')
                if (!lengthDataBase) {
                    setState(DataBaseStateType.EMPTY)
                    return;
                }

                try {
                    const response = await apiService.post(HOST + '/haveupdate?database=' + dataBaseName, {hash: hashDataBase})

                    if (!response.ok) throw new Error('Произошла ошибка при проверке хеша');

                    const {needupdate} = await response.json() as {needupdate: boolean}
                    if (needupdate) {
                        setState(DataBaseStateType.NEED_UPDATE);
                        return;
                    }
                } catch(error) {
                    console.error(error)
                    setState(DataBaseStateType.EMPTY)
                    return;
                }

                const currentDataBaseLength = dataBase.byteLength
                if (currentDataBaseLength !== +lengthDataBase) {
                    setState(DataBaseStateType.INCOMPLETE)
                    return;
                }

                setState(DataBaseStateType.EXIST)
                return;
            })()
        }, []);

        return <IndicatorComponent state={state} />
    }

    return WrapperComponent
};

export default withStateDeterminate;