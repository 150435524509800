import {BindParams, Database} from "sql.js";
import {Dispatch, SetStateAction} from "react";
import localforage from "localforage";
import {Identifiable} from "@app/interfaces";

export const loadDB = async (sqlService: any, fileName: string, setFunction: Dispatch<SetStateAction<Database | null>>) => {
    const db = await localforage.getItem<Uint8Array | null>(fileName)
    if (db) {
        setFunction(new sqlService.Database(db))
        return true
    } else {
        return false
    }
}

export const saveDataBase = async (name: string, dataBase: Database) => {
    await localforage.setItem(name + '_sqlite', dataBase.export())
    dataBase.run('PRAGMA foreign_keys = ON;')
}

export const executeQueryDataBase = <T>(dataBase: Database, query: string, params?: BindParams): Array<T> => {
        try {
            const result: Array<any> = []
            const statement = dataBase.prepare(query)
            if (process.env.NODE_ENV === 'development') {
                console.log('Выполнение запроса: ', statement.getSQL())
                console.log('Параметры запроса: ', params)
            }
            if (params) {
                statement.bind(params)
            }
            while (statement.step()) {
                result.push(statement.getAsObject())
            }
            statement.free()
            if (process.env.NODE_ENV === 'development') console.log('Результат выполнения запроса: ', result)
            return result as Array<T>
        } catch (error) {
            console.error('Произошла ошибка при выполнении запроса к базе даных: ', error)
            if (process.env.NODE_ENV === 'development') {
                console.error('Выполнение запроса: ', query)
                console.error('Параметры запроса: ', params)
            }
            return []
        }
}
