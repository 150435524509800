import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {DataBaseContext} from "@contexts/DataBaseContext";
import {useSQLService} from "@hooks/useSQLService";
import {Database} from "sql.js";
import localforage from 'localforage';
import {OBSERVATIONS_DATABASE, WEEDS_DATABASE} from "@constants/dataBase";
import {saveDataBase} from "@shared/helpersDB";
import {INITIAL_CREATE_OBSERVATIONS_DATABASE, INITIAL_CREATE_WEEDS_DATABASE} from "@constants/createQuery";

interface DataBaseProviderProps extends PropsWithChildren {

}

const DataBaseProvider: FC<DataBaseProviderProps> = ({children}) => {

    const {SQLService} = useSQLService()
    const [weedsDataBase, setWeedsDataBase] = useState<Database | null>(null)
    const [observationsDataBase, setObservationsDataBase] = useState<Database | null>(null)

    useEffect(() => {
        if (!SQLService) return;
        loadWeedsDataBase()
        loadObservationsDataBase()
    }, [SQLService]);

    const loadWeedsDataBase = async () => {
        const weedsDataBase = await localforage.getItem<Uint8Array | null>(WEEDS_DATABASE + '_sqlite')
        const allLength = await localforage.getItem<string | null>(WEEDS_DATABASE + '_length')
        if (!weedsDataBase || !allLength) return;
        const currentLength = weedsDataBase.byteLength
        if (+allLength !== currentLength) return;
        setWeedsDataBase(new SQLService.Database(weedsDataBase))
    }

    const loadObservationsDataBase = async () => {
        const observationsDataBase = await localforage.getItem<Uint8Array | null>(OBSERVATIONS_DATABASE + '_sqlite')
        if (observationsDataBase) {
            setObservationsDataBase(new SQLService.Database(observationsDataBase))
        } else {
            setObservationsDataBase(new SQLService.Database())
        }
    }

    useEffect(() => {
        (async () => {
            if (!observationsDataBase) return;
            observationsDataBase.run(INITIAL_CREATE_OBSERVATIONS_DATABASE)
            await saveDataBase(OBSERVATIONS_DATABASE, observationsDataBase)
        })()
    }, [observationsDataBase]);

    return (
        <DataBaseContext.Provider value={{
            weedsDataBase,
            observationsDataBase,
            loadWeedsDataBase
        }}>
            {children}
        </DataBaseContext.Provider>
    );
};

export default DataBaseProvider;