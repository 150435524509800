import React, {ChangeEventHandler, useEffect, useMemo, useState} from 'react';
import Header from "@components/Header/Header";
import {useDataBase} from "@hooks/useDataBase";
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";
import {Named} from "@interfaces/index";
import {executeQueryDataBase} from "@shared/helpersDB";
import {SELECT_CROPS_FROM_WEEDS} from "@constants/selectQuery";
import Input from "@components/Input/Input";
import CropCard from "@components/Card/CropCard/CropCard";

import styles from './crops.module.scss'
import {useDebounce} from "@hooks/useDebounce";
import Wrapper from "@components/Wrapper/Wrapper";

const Crops = () => {

    const {weedsDataBase} = useDataBase()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const [crops, setCrops] = useState<Array<Named>>([])
    const [searchValue, setSearchValue] = useState('')

    const debouncedValue = useDebounce(searchValue)

    const searchedCrops = useMemo(() => {
        if (debouncedValue === '') {
            return crops
        } else {
            return crops.filter(crop => {
                const splittedName = crop.name.split('-')
                if (splittedName[splittedName.length - 1] === 'П') {
                    return splittedName[splittedName.length - 3].toLowerCase().startsWith(debouncedValue.toLowerCase())
                } else {
                    return splittedName[splittedName.length - 2].toLowerCase().startsWith(debouncedValue.toLowerCase())
                }
            })
        }
    }, [debouncedValue, crops])

    useEffect(() => {
        if (!weedsDataBase) return;
        setCrops(executeQueryDataBase(weedsDataBase, SELECT_CROPS_FROM_WEEDS, {
            '$year': searchParams.get('year')!,
            '$region_id': searchParams.get('region')!,
            '$district_id': searchParams.get('district')!,
            '$cult_id': searchParams.get('culture')!,
            '$cult_type_id': searchParams.get('cultureType')! === 'NULL' ? null : searchParams.get('cultureType')!
        }))
    }, [weedsDataBase]);

    const handleChangeSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event.target.value.length > 3) {
            event.target.value = event.target.value.slice(0, 3)
        }
        setSearchValue(event.target.value)
    }

    const handleClickCard = (id: string) => {
        navigate(id.toString())
    }

    return (
        <>
            <Header title={'Посевы'}/>
            <Input placeholder={'Поиск по полю'} value={searchValue} onChange={handleChangeSearch}/>
            <Wrapper>
                {searchedCrops.length !== 0 ?
                    searchedCrops.map(crop =>
                        <CropCard
                            key={crop.id}
                            crop={crop}
                            onClickCard={handleClickCard}
                        />
                    ) :
                    <p style={{textAlign: 'center'}}>Данных нет</p>
                }
            </Wrapper>
        </>
    );
};

export default Crops;