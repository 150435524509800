import React, {FC} from 'react';
import {IObservation, IViewObservation} from "@interfaces/observation";

import styles from './ObservationBody.module.scss'

interface ObservationBodyProps {
    observation: IViewObservation
}

const ObservationBody: FC<ObservationBodyProps> = ({observation}) => {

    const {phenophase, place, fullness, note} = observation

    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapperGrid}>
                <div><b>Фенофаза:</b> {phenophase}</div>
                <div><b>Место в посеве:</b> {place}</div>
                <div><b>Обилие:</b> {fullness}</div>
            </div>
            {note && <div className={styles.note}>
                <b>Примечание: </b>
                {note}
            </div>}
        </div>
    );
};

export default ObservationBody;