import React, {useEffect, useMemo, useState} from 'react';
import Header from "@components/Header/Header";
import {useDataBase} from "@hooks/useDataBase";
import {createSearchParams, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {ICropStage, IStage} from "@interfaces/crops";
import {executeQueryDataBase, saveDataBase} from "@shared/helpersDB";
import {
    SELECT_CROP_STAGES_BY_CROP_FROM_OBSERVATIONS,
    SELECT_PHENOPHASES_BY_CROP_FROM_OBSERVATIONS,
    SELECT_PHENOPHASES_BY_CROP_FROM_WEEDS, SELECT_PHENOPHASES_FROM_WEEDS
} from "@constants/selectQuery";
import CropStageCard from "@components/Card/CropStageCard/CropStageCardProps";
import Wrapper from "@components/Wrapper/Wrapper";
import Autocomplete from "@components/Autocomplete/Autocomplete";
import {Named} from "@app/interfaces";
import LinkButton from "@components/LinkButton/LinkButton";
import {compareDate} from "@shared/helpers";
import {OBSERVATIONS_DATABASE, WEEDS_DATABASE} from "@constants/dataBase";
import {TRANSACTION_DELETE_CROP_STAGES} from "@constants/transactionQuery";
import ConfirmationModal from "@components/Modal/ConfirmationModal/ConfirmationModal";
import {IPhenophase} from "@interfaces/phenophases";

const CropStages = () => {

    const navigate = useNavigate()
    const {weedsDataBase, observationsDataBase} = useDataBase()
    const {crop_id} = useParams()

    const [isOpen, setIsOpen] = useState(false)
    const [stages, setStages] = useState<Array<IStage>>([])
    const [phenophases, setPhenophases] = useState<Array<Named>>([])
    const [phenophase, setPhenophase] = useState<Named | null>(null)
    const [currentCropStage, setCurrentCropStage] = useState<IStage | null>(null)

    const syncStages = useMemo(() => {
        if (!phenophase) return stages.filter(stage => stage.isSync)
        return stages.filter(stage => stage.phenophase_id === phenophase.id && stage.isSync)
    }, [stages, phenophase])

    const unsyncStages = useMemo(() => {
        if (!phenophase) return stages.filter(stage => !stage.isSync)
        return stages.filter(stage => stage.phenophase_id === phenophase.id && !stage.isSync)
    }, [stages, phenophase])

    const lastStageId = useMemo(() => {
        if (stages.length === 0) return null;
        return stages.sort(compareDate)[stages.length - 1].id
    }, [stages])

    useEffect(() => {
        if (!observationsDataBase || !weedsDataBase) return;
        const cropStages = executeQueryDataBase<ICropStage>(observationsDataBase, SELECT_CROP_STAGES_BY_CROP_FROM_OBSERVATIONS, {
            '$crop_id': crop_id || ''
        })
        setStages(cropStages.map(stage => {
            const phenophase = executeQueryDataBase<IPhenophase>(weedsDataBase, SELECT_PHENOPHASES_FROM_WEEDS, {
                '$phenophase_id': stage.phenophase_id
            })[0]
            console.log({
                ...stage,
                name: phenophase.name
            })
            return ({
                ...stage,
                name: phenophase.name
            })
        }))
        const phenophasesIdByCrop = executeQueryDataBase<{phenophase_id: string}>(observationsDataBase, SELECT_PHENOPHASES_BY_CROP_FROM_OBSERVATIONS, {
            '$crop_id': crop_id || ''
        }).map(phenophases => phenophases.phenophase_id)
        setPhenophases(executeQueryDataBase<Named>(weedsDataBase, SELECT_PHENOPHASES_BY_CROP_FROM_WEEDS(phenophasesIdByCrop)))
    }, [weedsDataBase, observationsDataBase]);

    const handleClickCard = (id: string) => {
        if (lastStageId && id.toString() === lastStageId.toString()) {
            navigate(id, {state: {editable: true}})
        } else {
            navigate(id, {state: {editable: false}})
        }
    }

    const handleEditCropStage = (stage: IStage) => {
        navigate(stage.id + '/edit')
    }

    const handleClickDeleteCropStage = (stage: IStage) => {
        setCurrentCropStage(stage)
        setIsOpen(true)
    }
    const deleteCropStage = async () => {
        if (!observationsDataBase || !currentCropStage) return;
        observationsDataBase.run(TRANSACTION_DELETE_CROP_STAGES(currentCropStage.id))
        await saveDataBase(OBSERVATIONS_DATABASE, observationsDataBase)
        setStages(stages => stages.filter(stage => stage.id !== currentCropStage.id))
        setCurrentCropStage(null)
        setIsOpen(false)
    }

    return (
        <>
            <Header title={'Стадии посева'} />
            <Autocomplete
                placeholder={'Поиск по стадии'}
                options={phenophases}
                value={phenophase}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                    setPhenophase(value)
                }}
            />
            <LinkButton to={'new_crop_stage'}>Добавить стадию</LinkButton>
            <Wrapper>
                <p>Несинхронизованные стадии посева</p>
                {unsyncStages.length !== 0 ?
                    unsyncStages.map(stage => {
                        if (stage.id === lastStageId) {
                            return <CropStageCard
                                key={stage.id}
                                stage={stage}
                                onClick={() => handleClickCard(stage.id.toString())}
                                onEdit={handleEditCropStage}
                                onDelete={handleClickDeleteCropStage}
                            />
                        }
                        return <CropStageCard key={stage.id} stage={stage} onClick={() => handleClickCard(stage.id.toString())}/>
                    }) :
                    <p style={{textAlign: 'center'}}>Данных нет</p>
                }
            </Wrapper>
            <Wrapper>
                <p>Синхронизованные стадии посева</p>
                {syncStages.length !== 0 ?
                    syncStages.map(stage => {
                        if (stage.id === lastStageId) {
                            return <CropStageCard
                                key={stage.id}
                                stage={stage}
                                onClick={() => handleClickCard(stage.id.toString())}
                                onEdit={handleEditCropStage}
                                onDelete={handleClickDeleteCropStage}
                            />
                        }
                        return <CropStageCard key={stage.id} stage={stage}
                                       onClick={() => handleClickCard(stage.id.toString())}/>
                    }) :
                    <p style={{textAlign: 'center'}}>Данных нет</p>
                }
            </Wrapper>
            <ConfirmationModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={'Вы уверены, что хотите удалить стадию посева?'}
                subtitle={'Отменить данное действие будет невозможно.'}
                onReject={() => setIsOpen(false)}
                onResolve={deleteCropStage}
            />
        </>
    );
};

export default CropStages;