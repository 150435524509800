export type ApiServiceResponseType = Promise<Response>
export type ObjectType = Record<string, unknown> | null | undefined | FormData | string

export interface Params {
    param: string
    value: string
}

export const baseConfig: RequestInit = {
    headers: {
        'Content-Type': 'application/json'
    }
}