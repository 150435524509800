import Header from '@app/components/Header/Header';
import React, {useEffect, useState} from 'react';
import {useDataBase} from "@hooks/useDataBase";
import {INotification} from "@interfaces/notification";
import {executeQueryDataBase} from "@shared/helpersDB";
import {SELECT_NOTIFICATIONS_FROM_WEEDS} from "@constants/selectQuery";
import Wrapper from '@app/components/Wrapper/Wrapper';
import NotificationCard from "@components/NotificationCard/NotificationCard";
import {UPDATE_READ_NOTIFICATION_INTO_WEEDS} from "@constants/updateQuery";
import {useNotifications} from "@hooks/useNotifications";
import {useLocation} from "react-router-dom";
import {useAlerts} from "@hooks/useAlerts";

const Notifications = () => {

    const {removeAlert, alerts} = useAlerts()
    const {notifications, readNotification} = useNotifications()
    const {state}: {
        state: {
            notification_id?: string
        }
    } = useLocation()

    useEffect(() => {
        const unreadNotificationsIds = notifications.filter(notification => !notification.isRead).map(notification => notification.id)
        alerts.forEach(alert => {
            if (unreadNotificationsIds.includes(alert.properties?.notification_id)) {
                removeAlert(alert)
            }
        })
    }, [notifications, alerts]);

    return (
        <>
            <Header title={'Оповещения'}/>
            <Wrapper>
                {
                    notifications.map(notification => {
                        if (state && state?.notification_id && state.notification_id.toString() === notification.id.toString()) {
                            return <NotificationCard
                                key={notification.id}
                                expanded={true}
                                notification={notification}
                                onExpand={readNotification}
                            />
                        }
                        return <NotificationCard
                            key={notification.id}
                            notification={notification}
                            onExpand={readNotification}
                        />
                    })
                }
            </Wrapper>
        </>
    );
};

export default Notifications;