import React, {FC, PropsWithChildren, useState} from 'react';
import {AlertContext} from "@contexts/AlertContext";
import {IAlertType, IAlertTypes} from "@interfaces/alert";
import {v4 as uuid} from 'uuid';

interface NotificationProviderProps extends PropsWithChildren {

}

const AlertProvider: FC<NotificationProviderProps> = ({children}) => {

    const [alerts, setAlerts] = useState<Array<IAlertType>>([])

    const addAlert = (alert: IAlertType) => {
        setAlerts(existAlerts => [
            ...existAlerts,
            {
                ...alert
            }
        ])
    }

    const removeAlert = (alert: IAlertType) => {
        setAlerts(existAlerts => existAlerts.filter(existAlert => existAlert.id !== alert.id))
    }

    const removeAlertById = (id: string) => {
        setAlerts(existAlerts => existAlerts.filter(existAlert => existAlert.id !== id))
    }

    return (
        <AlertContext.Provider value={{
            alerts,
            addAlert,
            removeAlert,
            removeAlertById
        }}>
            {children}
        </AlertContext.Provider>
    );
};

export default AlertProvider;