import React, {FC, useEffect, useState} from 'react';
import {Named} from "@app/interfaces";
import moment, {Moment} from "moment/moment";
import {Controller, useForm} from "react-hook-form";
import Autocomplete from "@components/Autocomplete/Autocomplete";
import StyledDatePicker from "@components/StyledDatePicker/StyledDatePicker";
import Button from "@components/Button/Button";
import {IStage} from "@interfaces/crops";
import {executeQueryDataBase} from "@shared/helpersDB";
import {SELECT_PHENOPHASES_BY_CULTURE_FROM_WEEDS} from "@constants/selectQuery";
import {useDataBase} from "@hooks/useDataBase";
import {useParams} from "react-router-dom";

export interface StageFormValues {
    phenophase: Named | null
    date: Moment | null
}

interface CropStageFormProps {
    cropStage?: IStage
    onSubmit: (data: StageFormValues) => void
    buttonTitle?: string
}

const CropStageForm: FC<CropStageFormProps> = ({cropStage, buttonTitle = 'Добавить', onSubmit}) => {

    const {weedsDataBase} = useDataBase()
    const {crop_id} = useParams()
    const {control, handleSubmit} = useForm<StageFormValues>({
        defaultValues: {
            phenophase: cropStage ? {id: cropStage.phenophase_id, name: cropStage.name} : null,
            date: cropStage ? moment(cropStage.date) : moment()
        }
    })

    const [phenophases, setPhenophases] = useState<Array<Named>>([])

    useEffect(() => {
        if (!weedsDataBase) return;
        setPhenophases(executeQueryDataBase<Named>(weedsDataBase, SELECT_PHENOPHASES_BY_CULTURE_FROM_WEEDS, {
            '$crop_id': crop_id || ''
        }))
    }, [weedsDataBase]);

    return (
        <form className='default-form' onSubmit={handleSubmit(onSubmit)}>
            <div className='form-field'>
                <label htmlFor="phenophase" className='form-label'>Стадия<span className='required'>*</span></label>
                <Controller
                    control={control}
                    name={'phenophase'}
                    rules={{
                        required: 'Поле обязательно для заполнения!'
                    }}
                    render={({field: {value, onChange}, fieldState: {error}}) => <>
                        <Autocomplete
                            id={'phenophase'}
                            placeholder={'Выберите стадию'}
                            options={phenophases}
                            error={!!error}
                            value={value}
                            getOptionLabel={option => option.name}
                            onChange={(event, value) => {
                                onChange(value)
                            }}
                        />
                        {error && <span className='form-error'>{error.message}</span>}
                    </>}
                />
            </div>
            <div className='form-field'>
                <label htmlFor="date" className='form-label'>Дата<span className='required'>*</span></label>
                <Controller
                    control={control}
                    name={'date'}
                    rules={{
                        required: 'Поле обязательно для заполнения!'
                    }}
                    render={({field: {value, onChange}, fieldState: {error}}) => <>
                        <StyledDatePicker
                            value={value}
                            onChange={onChange}
                        />
                        {error && <span className='form-error'>{error.message}</span>}
                    </>}
                />
            </div>
            <Button>{buttonTitle}</Button>
        </form>
    );
};

export default CropStageForm;