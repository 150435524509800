import React, {FC, PropsWithChildren} from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {IconButton} from "@mui/material";

import styles from './Modal.module.scss';

export interface ModalProps extends PropsWithChildren {
    isOpen: boolean
    onClose: () => void
}

const Modal: FC<ModalProps> = ({isOpen, onClose, children}) => {

    return (
        isOpen ? <div className={styles.wrapper}>
            <div className={styles.content}>
                <IconButton aria-label="close" className={styles.close} size="small" onClick={onClose}>
                    <ClearIcon />
                </IconButton>
                {children}
            </div>
        </div> : <></>
    );
};

export default Modal;