import React, {PropsWithChildren} from 'react';
import {DatePicker, DatePickerProps} from "@mui/x-date-pickers";
import {createTheme, ThemeOptions, ThemeProvider} from "@mui/material/styles";
declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        }
    }

    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

const WeedsTheme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    fontFamily: 'TTNormRegular, sans-serif',
                    fontSize: '18px',
                    '&.Mui-focused': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#73C120 !important'
                        }
                    }
                },
                input: {
                  padding: '10px 10px'
                },
                notchedOutline: {
                    borderColor: 'transparent',
                    boxShadow: '0 4px 7px rgba(0, 0, 0, 0.25)'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#73C120'
                }
            }
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#73C120 !important'
                    }
                }
            }
        },
        MuiPickersYear: {
            styleOverrides: {
                yearButton: {
                    '&.Mui-selected': {
                        backgroundColor: '#73C120 !important'
                    }
                }
            }
        }
    }
} as ThemeOptions)

interface StyledDatePickerProps<TDate> extends DatePickerProps<TDate> {

}

const StyledDatePicker = <TDate, >({...props}: StyledDatePickerProps<TDate>) => {
    return (
        <ThemeProvider theme={WeedsTheme}>
            <DatePicker {...props}
                        format={'DD.MM.YYYY'}
                        localeText={{
                            toolbarTitle: 'Выбранная дата',
                            okButtonLabel: 'Сохранить',
                            cancelButtonLabel: 'Закрыть'
                        }}
            />
        </ThemeProvider>
    );
};

export default StyledDatePicker;