import React, {useEffect, useState} from 'react';
import {useAlerts} from "@hooks/useAlerts";
import {IAlertTypes} from "@interfaces/alert";
import InformationAlert from "@components/AlertWrapper/InformationAlert/InformationAlert";

import styles from './AlertWrapper.module.scss';

const AlertWrapper = () => {

    const {alerts} = useAlerts()

    return (
        <div className={styles.wrapper}>
            {alerts.length !==0 && alerts.map(alert => {
                    switch (alert.type) {
                        case IAlertTypes.INFORMATION:
                            return <InformationAlert key={alert.id} alert={alert} />
                        default:
                            return '';
                    }
                }
            )}
        </div>
    );
};

export default AlertWrapper;