import React, {useEffect, useState} from 'react';
import logo from '@assets/img/logo.png'
import LinkButton from "@components/LinkButton/LinkButton";
import NotificationBell from "@components/NotificationBell/NotificationBell";

import styles from './main.module.scss'
import {useDataBase} from "@hooks/useDataBase";
import {executeQueryDataBase} from "@shared/helpersDB";
import ConnectionStateIndicator from '@app/components/ConnectionStateIndicator/ConnectionStateIndicator';
import {HELPERS} from "@constants/helps";
import Modal from "@components/Modal/Modal";

const Main = () => {

    const {weedsDataBase} = useDataBase()

    const [isOpen, setIsOpen] = useState(false)
    const [helperModalText, setHelperModalText] = useState('')

    const handleClickHelp = (helpText: string) => {
        setIsOpen(true)
        setHelperModalText(helpText)
    }

    const handleClickCloseHelperModal = () => {
        setIsOpen(false)
        setHelperModalText('')
    }

    return (
        <div className={styles.wrapRoot}>
            {/*<ConnectionStateIndicator />*/}
            {/* <NotificationBell />*/}
            <img src={logo} alt='логотип' className={styles.logo}/>
            <h1 className={styles.header}>Сорные растения</h1>
            <div className={styles.wrapMain}>
                <div className={styles.wrap}>
                    <LinkButton
                        to={weedsDataBase ? '/search' : '/'}
                        disabled={!weedsDataBase}
                        helpText={HELPERS['weeds']}
                        onClickHelp={handleClickHelp}
                    >Наблюдения</LinkButton>
                </div>
                <div className={styles.wrap}>
                    <LinkButton to={'/synchronization'}>Синхронизовать данные</LinkButton>
                </div>
            </div>
            <Modal isOpen={isOpen} onClose={handleClickCloseHelperModal}>
                <p className="notification-message">{helperModalText}</p>
            </Modal>
        </div>
    );
};

export default Main;