import React, {FC, InputHTMLAttributes} from 'react';
import cn from 'classnames'

import styles from './Input.module.scss'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {

}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({className, ...params}, ref) => {
    return (
        <input ref={ref} className={cn(styles.input, {className: className})} {...params}/>
    );
})

export default Input;