import React, {ChangeEventHandler, FC} from 'react';

import styles from './CropCard.module.scss'
import Card, {CardProps} from "@components/Card/Card";
import {Named} from "@app/interfaces";
import {createSearchParams, useNavigate} from "react-router-dom";

interface CropCardProps extends CardProps {
    crop: Named
    onClickCard: (id: string) => void
}
const CropCard: FC<CropCardProps> = ({crop, onClickCard, ...props}) => {

    return (
        <Card className={styles.card} onClick={() => onClickCard(crop.id)} {...props}>
            <p className={styles.title}>{crop.name}</p>
        </Card>
    );
};

export default CropCard;