import React, {ButtonHTMLAttributes, DetailedHTMLProps, FC} from 'react';
import cn from 'classnames'

import styles from './Button.module.scss'

interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {

}

const Button: FC<ButtonProps> = ({className, children, ...params}) => {
    return (
        <button className={cn(styles.button, className)} {...params}>
            {children}
        </button>
    );
};

export default Button;