import {ICrop, IStage} from "@interfaces/crops";
import {IObservation} from "@interfaces/observation";
import {ICult, ICultType} from "@interfaces/cult";
import {IRegion} from "@interfaces/region";

export const TRANSACTION_UPDATE_UNSYNC_DATA_TO_SYNC = (cropStageIds: Array<string>, observationIds: Array<string>) => {
    return `
        BEGIN TRANSACTION;
        
        UPDATE crop_stages
        SET isSync=1
        WHERE id IN (${cropStageIds.join(',')});
        
        UPDATE observations
        SET isSync=1
        WHERE id IN (${observationIds.join(',')});
        
        COMMIT;
    `
}

export const TRANSACTION_DELETE_DATA = (cropStageIds: Array<string>, observationIds: Array<string>) => {
    return `
        BEGIN TRANSACTION;
        
        DELETE FROM crop_stages
        WHERE id IN (${cropStageIds.join(',')});
        
        DELETE FROM observations
        WHERE id IN (${observationIds.join(',')});
        
        COMMIT;
    `
}

export const TRANSACTION_DELETE_CROP_STAGES = (cropStageId: string) => {
    return `
        BEGIN TRANSACTION;
        
        UPDATE crop_stages
        SET isDeleted = 1
        WHERE id='${cropStageId}';
        
        UPDATE observations
        SET isDeleted = 1
        WHERE crop_stage_id='${cropStageId}';
        
        COMMIT;
    `
}

export const TRANSACTION_UPDATE_CROP_STAGES_TABLE = (cropStages: Array<IStage>) => {
    return `
        BEGIN TRANSACTION;
        
        DELETE FROM crop_stages;
        
        INSERT INTO crop_stages (id, crop_id, date, createdAt, phenophase_id, isSync, isDeleted) 
        VALUES ${cropStages.map((stage, idx) => {
                return `('${stage.id}', ${stage.crop_id}, '${stage.date}', '${stage.createdAt}', ${stage.phenophase_id}, ${stage.isSync}, 0)`
        })};
        
        COMMIT;
    `
}

export const TRANSACTION_UPDATE_OBSERVATIONS_TABLE = (observations: Array<IObservation>) => {
    return `
        BEGIN TRANSACTION;
        
        DELETE FROM observations;
        
        INSERT INTO observations (id, crop_stage_id, infection_id, phenophase_id, place, fullness, user, note, isSync, isDeleted)
        VALUES ${observations.map((observation, idx) => {
        return `('${observation.id}', '${observation.crop_stage_id}', ${observation.infection_id}, ${observation.phenophase_id}, '${observation.place}', '${observation.fullness}', '${observation.user}', '${observation.note}', ${observation.isSync}, 0)`
        })};
        
        COMMIT;
    `
}

export const TRANSACTION_UPDATE_CROPS_TABLE = (crops: Array<ICrop>) => {
    return `
        BEGIN TRANSACTION;
        
        DELETE FROM crops;
        
        INSERT INTO crops (id, name, cult_id, reseeding, cult_type_id, region_id, district_id, year, field)
        VALUES ${crops.map((crop, idx) => {
        const isLastElement = idx === crops.length - 1
        if (isLastElement) {
            return `(${crop.id}, ${crop.name}, ${crop.cult_id}, ${crop.reseeding}, ${crop.cult_type_id}, ${crop.region_id}, ${crop.district_id}, ${crop.year}, ${crop.field});`
        } else {
            return `(${crop.id}, ${crop.name}, ${crop.cult_id}, ${crop.reseeding}, ${crop.cult_type_id}, ${crop.region_id}, ${crop.district_id}, ${crop.year}, ${crop.field}),`
        }
    })}
        
        COMMIT;
    `
}

export const TRANSACTION_UPDATE_CULTS_TABLE = (cults: Array<ICult>) => {
    return `
        BEGIN TRANSACTION;
        
        DELETE FROM cults;
        
        INSERT INTO cults (id, name, phenoclass_id)
        VALUES ${cults.map((cult, idx) => {
        const isLastElement = idx === cults.length - 1
        if (isLastElement) {
            return `(${cult.id}, ${cult.name}, ${cult.phenoclass_id});`
        } else {
            return `(${cult.id}, ${cult.name}, ${cult.phenoclass_id}),`
        }
    })}
        
        COMMIT;
    `
}

export const TRANSACTION_UPDATE_CULT_TYPES_TABLE = (cultTypes: Array<ICultType>) => {
    return `
        BEGIN TRANSACTION;
        
        DELETE FROM cult_types;
        
        INSERT INTO cult_types (id, name)
        VALUES ${cultTypes.map((cultType, idx) => {
        const isLastElement = idx === cultTypes.length - 1
        if (isLastElement) {
            return `(${cultType.id}, ${cultType.name});`
        } else {
            return `(${cultType.id}, ${cultType.name}),`
        }
    })}
        
        COMMIT;
    `
}

export const TRANSACTION_UPDATE_REGIONS_TABLE = (regions: Array<IRegion>) => {
    return `
        BEGIN TRANSACTION;
        
        DELETE FROM regions;
        
        INSERT INTO regions (id, name)
        VALUES ${regions.map((region, idx) => {
        const isLastElement = idx === regions.length - 1
        if (isLastElement) {
            return `(${region.id}, ${region.name});`
        } else {
            return `(${region.id}, ${region.name}),`
        }
    })}
        
        COMMIT;
    `
}