import React, {FC} from 'react';
import {Link, LinkProps} from "react-router-dom";
import cn from 'classnames'

import styles from './LinkButton.module.scss'
import {WithHelp} from "@interfaces/ui";
import {IconButton} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

interface LinkButtonProps extends LinkProps, React.RefAttributes<HTMLAnchorElement>, WithHelp {
    disabled?: boolean
}

const LinkButton: FC<LinkButtonProps> = ({style, disabled = false, className, children, onClickHelp, helpText, ...params}) => {

    const handleHelpClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!helpText || !onClickHelp) return;
        event.preventDefault();
        event.stopPropagation();
        onClickHelp(helpText)
    }

    return (
        <Link className={cn(styles.button, {className: className}, {[styles.disabled]: disabled})}  {...params}>
            {children}
            {helpText && <IconButton aria-label="help" className={cn(styles.helpIcon, styles.white)} onClick={handleHelpClick}>
                <HelpOutlineOutlinedIcon />
            </IconButton>}
        </Link>
    );
};

export default LinkButton;