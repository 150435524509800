import React from 'react';
import ReactDOM from 'react-dom/client';
import '@assets/styles/global.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {routes} from "@app/routes";

import 'normalize.css'

const router = createBrowserRouter(routes)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
        <RouterProvider router={router}/>
);

serviceWorkerRegistration.register();
