import React, {useEffect, useState} from 'react';
import Indicator, {IndicatorProps} from "@components/Indicators/Indicator";
import {DataBaseStateType} from "@interfaces/database";
import withStateDeterminate from "@app/hoc/withStateDeterminate";
import apiService from "@services/ApiService";
import {HOST} from "@shared/enviroment";
import localforage from "localforage";
import {WEEDS_DATABASE} from "@constants/dataBase";
import {mergedUint8Arrays} from '@app/shared/helpers';
import {useDataBase} from "@hooks/useDataBase";

const SynchronizationWeeds = ({state}: { state: DataBaseStateType | null }) => {

    const {loadWeedsDataBase} = useDataBase()
    const [synchronizationState, setSynchronizationState] = useState<IndicatorProps>({
        type: 'loading',
        title: 'Проверка наличия обновлений'
    })

    useEffect(() => {
        if (!state) return;
        switch (state) {
            case DataBaseStateType.EMPTY:
                fullUpdateDataBase()
                break;
            case DataBaseStateType.NEED_UPDATE:
                fullUpdateDataBase()
                break;
            case DataBaseStateType.INCOMPLETE:
                partUpdateDataBase()
                break;
            case DataBaseStateType.EXIST:
                setSynchronizationState({type: 'success', title: 'База данных сорных растений не требует обновления'})
                break;
            default:
                break;
        }
    }, [state]);

    const fullUpdateDataBase = async () => {
        try {
            const chunks: Uint8Array[] = []
            const response = await apiService.get(HOST + '/database', {
                headers: {
                    'Content-Type': 'application/json',
                    'Current-position': '0'
                }
            })

            if (!response.ok) {
                throw new Error('Не удалось скачать базу данных. Попробуйте позже.')
            }

            if (response.headers && response.body) {
                let receivedLength = 0

                const reader = response.body.getReader();

                const hash = response.headers.get('Hash')
                const allLength = response.headers.get('Content-Length')

                await localforage.setItem(WEEDS_DATABASE + '_hash', hash)
                await localforage.setItem(WEEDS_DATABASE + '_length', allLength)

                while (true) {
                    const {done, value} = await reader.read()
                    if (done) {
                        break;
                    }
                    if (value) {
                        const expectedLength = allLength
                        chunks.push(value)
                        receivedLength += value.length
                        if (expectedLength) {
                            let percentLength = Math.floor((receivedLength / +expectedLength) * 100)
                            setSynchronizationState({
                                type: 'loading',
                                title: 'Загрузка базы данных',
                                progress: percentLength
                            })
                        }
                    }
                }

                const installedFile = await localforage.setItem(WEEDS_DATABASE + '_sqlite', mergedUint8Arrays(chunks))
                const controlLength = await localforage.getItem<string | null>(WEEDS_DATABASE + '_length')
                await localforage.setItem(WEEDS_DATABASE + '_current_length', installedFile?.byteLength.toString())

                if (controlLength && installedFile.byteLength === +controlLength) {
                    setSynchronizationState({type: 'success', title: 'База данных успешно обновлена'})
                    //Если всё скачалось, выполняем загрузку файла в sql-wasm
                    loadWeedsDataBase()
                }
            }
        } catch (error) {
            console.error(error)
            if (typeof error === 'string') {
                setSynchronizationState({type: 'failed', title: error})
            }
            setSynchronizationState({type: 'failed', title: 'Не удалось скачать базу данных. Попробуйте позже.'})
        }
    }

    const partUpdateDataBase = async () => {
        try {
            const chunks: Uint8Array[] = []
            const currentLength = await localforage.getItem<string | null>(WEEDS_DATABASE + '_current_length')
            const controlLength = await localforage.getItem<string | null>(WEEDS_DATABASE + '_length')

            const response = await apiService.get(HOST + '/database', {
                headers: {
                    'Content-Type': 'application/json',
                    'Current-position': currentLength ?? '0'
                }
            })

            if (!response.ok) {
                throw new Error('Не удалось скачать базу данных. Попробуйте позже.')
            }

            if (response.headers && response.body) {
                let receivedLength = currentLength ? +currentLength : 0

                const reader = response.body.getReader();
                while (true) {
                    const {done, value} = await reader.read()
                    if (done) {
                        break;
                    }
                    if (value) {
                        const expectedLength = controlLength ? controlLength : 100000000
                        chunks.push(value)
                        receivedLength += value.length
                        if (expectedLength) {
                            let percentLength = Math.floor((receivedLength / +expectedLength) * 100)
                            setSynchronizationState({
                                type: 'loading',
                                title: 'Загрузка базы данных',
                                progress: percentLength
                            })
                        }
                    }
                }

                const file = await localforage.getItem<Uint8Array>(WEEDS_DATABASE + '_db')
                if (file) {
                    chunks.unshift(file)
                }

                const installedFile = await localforage.setItem(WEEDS_DATABASE + '_db', mergedUint8Arrays(chunks))
                await localforage.setItem(WEEDS_DATABASE + '_current_length', installedFile?.byteLength.toString())

                if (controlLength && installedFile.byteLength === +controlLength) {
                    setSynchronizationState({type: 'success', title: 'База данных успешно обновлена'})
                    //Если всё скачалось, выполняем загрузку файла в sql-wasm
                    loadWeedsDataBase()
                }
            }
        } catch (error) {
            console.log(error)
            if (typeof error === 'string') {
                setSynchronizationState({type: 'failed', title: error})
            }
            setSynchronizationState({type: 'failed', title: 'Не удалось скачать базу данных. Попробуйте позже.'})
        }
    }

    return (
        <Indicator type={synchronizationState.type} title={synchronizationState.title}/>
    );
};

export default withStateDeterminate(SynchronizationWeeds);